/*    
<summary>
   This component is Used for Activating Huckster Account.
   Developer: Mohammad Saquib Khan, Created Date:14-May-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns JSX</returns>
*/

import "./reset-password.css";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import "react-sliding-side-panel/lib/index.css";
import { useHistory, useLocation } from "react-router-dom";
import { useStore } from "../../../contexts/store-provider";
import ResetPasswordForm from "../forms/reset-password-form";
import toast from "react-hot-toast";
import { formatMessage } from "../../../translations/format-message";
import RoutesConstants from "../../../constants/routes.constant";
import base64 from "base-64";

const css = `
body, html {
  overflow: visible;
  height: auto;
background: #252027;
}
`;

const ResetPassword = (props: any) => {
  const history = useHistory();
  const { userStore } = useStore();
  const {
    VerifyUserByUserIdService,
    userState,
    userDetail,
    resetUserDetail,
    changePasswordState,
    ResetPasswordService,
    resetChangePassword
  } = userStore;
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const userId = params.get("uid") ? decodeURIComponent(escape(atob(params.get("uid")!))) : null;
  const email = params.get("em") ? decodeURIComponent(escape(atob(params.get("em")!))) : null;

  useEffect(() => {
    resetUserDetail();
    if (userId && email) {
      VerifyUserByUserIdService(Number(userId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (changePasswordState.success) {
      toast.success(formatMessage("password_reset_successfully"));
      history.push(RoutesConstants.Login);
    }
  }, [changePasswordState.success]);

  useEffect(() => {
    if (changePasswordState.error) {
      toast.error(formatMessage(changePasswordState.error));
      resetChangePassword();
    }
  }, [changePasswordState.error]);

  /**
   * This function add media when "Id < 0" and updates existing user when Id > 0 by providing form values to corresponding function
   */
  const onSubmitForm = (values: any) => {
    ResetPasswordService({
      UserId: values.UserId,
      NewPassword: base64.encode(values.ConfirmPassword),
    });
  };

  return (
    <React.Fragment>
      <div className="bg-img3 background">
        <ResetPasswordForm
          userDetail={userDetail}
          submitHandler={onSubmitForm}
          isDataLoading={changePasswordState.inProgress || userState.inProgress}
          isLoading={changePasswordState.inProgress || userState.inProgress}
        />
        <style>{css}</style>
      </div>
    </React.Fragment>
  );
};

export default observer(ResetPassword);
