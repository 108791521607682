/*    
<summary>
   This component is Used for Activating Huckster Account.
   Developer: Mohammad Saquib Khan, Created Date:14-May-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns JSX</returns>
*/

import "./account-activation.css";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import "react-sliding-side-panel/lib/index.css";
import noMessageIcon from "../../assets/Images/svg/018-empty.svg";
import { formatMessage } from "../../translations/format-message";
import FormLoader from "../../shared-components/FormLoader/FormLoader";
import { useLocation } from "react-router-dom";
import { useStore } from "../../contexts/store-provider";
import accountStatusEnum from "../../constants/enums/account-status-type-enum";

const AccountActivation = (props: any) => {
  const { userStore } = useStore();
  const {
    ActivateUserService,
    activateUserAccountState,
    resetAccountActivationState,
  } = userStore;
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const userId = params.get("uid") ? decodeURIComponent(escape(atob(params.get("uid")!))) : null;
  const email = params.get("em") ? decodeURIComponent(escape(atob(params.get("em")!))) : null;
  console.log(userId);
  console.log(email);

  useEffect(() => {
    resetAccountActivationState();
    if (userId && email) {
      ActivateUserService(Number(userId), email, accountStatusEnum.Active);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      {<FormLoader loading={activateUserAccountState.inProgress} />}

      <div className="message text-center">
        <img src={noMessageIcon} alt="" className="no_message" />
        <p className="noData text-muted text-center h4 mb-0">
          <FormattedMessage id="activating_account" />
        </p>
        {activateUserAccountState.error != "" ? (
          <p className="mb-3">
            {formatMessage(activateUserAccountState.error)}
          </p>
        ) : (
          ""
        )}
      </div>
    </React.Fragment>
  );
};

export default observer(AccountActivation);
