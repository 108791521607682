import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import "./slider.css";
import { formatMessage } from "../../translations/format-message";
import { useEffect } from "react";

interface IProps {
  value: number;
  marks: any[];
  className?: string;
  count?: number;
  min: number;
  max: number;
  categoryType: number;
  submitHandler: (
    event: Event,
    value: number | number[],
    categoryType: number
  ) => void;
}

const SliderComponentSingle: React.FC<IProps> = (props) => {
  const [railColor, setRailColorCode] = React.useState<string>("#F39800");
  const [thumbColor, setThumbColorCode] = React.useState<string>("#FF0000");
  const handleChange = (event: Event, value: number | number[]) => {
    changeColorCode(value);
    props.submitHandler(event, value, props.categoryType);
  };

  // useEffect(() => {
  //   if(props.value == props.max){
  //     debugger
  //     setRailColorCode("#999");
  //     // setRailColorCode("#F39800");
  //     // setRailColorCode("#F39800");
  //     setThumbColorCode("#FF0000");
  //     console.log("Color Changed");
  //   }
  // }, [props.value]);

  const changeColorCode = (value: any) => {
    if (value === props.max) {
      setRailColorCode("#999");
      setThumbColorCode("#F39800");
    } else {
      setRailColorCode("#F39800");
      setThumbColorCode("#FF0000");
    }
  };

  return (
    <Box sx={{ width: 300 }}>
      <Slider
        track={"normal"}
        min={props.min}
        max={props.max}
        aria-labelledby="track-false-slider"
        valueLabelDisplay="auto"
        valueLabelFormat={(value) => {
          changeColorCode(value);
          return value === props.max ? formatMessage("always_crowded") : value;
        }}
        // getAriaValueText={valuetext}
        onChange={handleChange}
        value={Number(props.value)}
        marks={props.marks}
        sx={{
          "& .MuiSlider-track": {
            bgcolor: railColor, // Change the color of the track
            border: "none",
            opacity: 1,
          },
          "& .MuiSlider-rail": {
            bgcolor: "#FF0000", // Change the color of the rail (background track)
            opacity: 1,
          },
          '& .MuiSlider-thumb[data-index="0"]': {
            bgcolor: thumbColor, // Change the color of the first thumb
          },
          "& .MuiSlider-mark": {
            top: "-10px", // Adjust the position of the marks
          },
          "& .MuiSlider-markLabel": {
            top: "-25px", // Adjust the position of the mark labels
          },
          "& .MuiSlider-valueLabel": {
            //   bgcolor: 'blue', // Change the background color of the value label
          },
        }}
      />
    </Box>
  );
};
export default SliderComponentSingle;
