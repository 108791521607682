import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { ModuleType } from "../../../constants/enums/module-type-enum";
import { isOperationPermittedParkingRoleBase } from "../../../helpers/action-permission-helper";
import FormLoader from "../../../shared-components/FormLoader/FormLoader";
import LoaderButton from "../../../shared-components/button/loader-button";
import SliderComponentSingle from "../../../shared-components/slider/slider-single";
import { formatMessage } from "../../../translations/format-message";
import "./add-edit-integrator-threshold-form.css";
import { getCurrentLanguageFromLocalStorage } from "../../../helpers/localstorage-helper";

interface IProps {
  id: number;
  initialValues: any;
  integratorID: string;
  submitHandler: (data: any) => void;
  onModalClosed: () => void;
  isLoading: boolean;
}

const AddUpdateIntegratorThresholdForm = (props: IProps) => {
  const [thresholdData, setThresholdData] = useState<any>();

  useEffect(() => {
    let marks = [];
    for (let i = 0; i <= props.initialValues?.TotalParkingCount; i++) {
      marks.push({
        value: i,
        label: i?.toString(),
      });
      if (i == props.initialValues?.TotalParkingCount) {
        marks.push({
          value: props.initialValues?.TotalParkingCount + 1,
          label: formatMessage("always_crowded"),
        });
      }
    }
    const transformedData = {
      ThresholdId: props.initialValues?.ThresholdId,
      TotalParkingCount: props.initialValues?.TotalParkingCount,
      AvailUpto: props.initialValues?.AvailUpto,
      FullAfter: props.initialValues?.FullAfter,
      IsCrowded: props.initialValues?.IsCrowded,
      Marks: marks,
    };
    setThresholdData(transformedData);
  }, [props.initialValues]);

  const handleSliderChange = (event: any, value: number | number[]) => {
    if (event?.target?.value >= 0) {
      setThresholdData((prevObject: any) => ({
        ...prevObject,
        AvailUpto: 0,
        IsCrowded:
          event?.target?.value == thresholdData?.TotalParkingCount + 1
            ? true
            : false,
        FullAfter: event?.target?.value,
      }));
    }
  };

  return (
    <>
      <Modal
        className="threshold-modal modal-xxl fancy-modal"
        show={true}
        centered
        onHide={props.onModalClosed}
      >
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id="threshold_integrator" />
            <button
              onClick={props.onModalClosed}
              disabled={props.isLoading}
              className="Crossicons"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="23"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </button>
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={props.initialValues}
          enableReinitialize
          // validationSchema={setThresholdValidationSchema}
          validateOnBlur={false}
          onSubmit={async (values) => {
            props.submitHandler({
              ...values,
              ThresholdSliderData: thresholdData,
            });
          }}
        >
          {({ dirty, setFieldValue }) => (
            <Form className="user-form">
              {props.isLoading && <FormLoader loading={props.isLoading} />}
              <Modal.Body>
                <div className="tableDesign border">
                  <table className="table table-bordered border threshold-table">
                    <thead>
                      <tr>
                        <th>
                          <FormattedMessage id="integrator_id" />
                        </th>
                        <th>
                          <FormattedMessage id="no_of_parking" />
                        </th>
                        <th>
                          <FormattedMessage id="in_all_parking_lots" />
                        </th>
                        <th>
                          <div className="d-flex mb-2">
                            <div className="circleGreen me-2"></div>
                            <FormattedMessage id="integrator_available_threshold" />
                            <span className="ms-1 ft-green">
                              <FormattedMessage id="available_color" />
                            </span>
                            {getCurrentLanguageFromLocalStorage() == "jp" && (
                              <span className="ms-1">
                                <FormattedMessage id="display" />
                              </span>
                            )}
                          </div>
                          <div className="d-flex mb-2">
                            <div className="circleYellow me-2"></div>
                            <FormattedMessage id="integrator_crowded_threshold" />
                            <span className="ms-1 ft-yellow">
                              <FormattedMessage id="crowded_color" />
                            </span>
                            {getCurrentLanguageFromLocalStorage() == "jp" && (
                              <span className="ms-1">
                                <FormattedMessage id="display" />
                              </span>
                            )}
                          </div>
                          <div className="d-flex mt-1">
                            <div className="circleRed me-2"></div>
                            <FormattedMessage id="integrator_full_threshold" />
                            <span className="ms-1 ft-red">
                              <FormattedMessage id="full_color" />
                            </span>
                            {getCurrentLanguageFromLocalStorage() == "jp" && (
                              <span className="ms-1">
                                <FormattedMessage id="display" />
                              </span>
                            )}
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="mt-4">
                        <td>{[props.integratorID]}</td>
                        <td>{thresholdData?.TotalParkingCount}</td>
                        <td>
                          <div className="text-center mt-1">
                            <p>
                              <FormattedMessage id="parking_lot_full" />
                            </p>
                            <OverlayTrigger
                              placement="left" // Tooltip position (top, bottom, left, right)
                              overlay={
                                <Tooltip id="tooltip-top">
                                  <FormattedMessage id="parking_lot_full_tooltip" />
                                </Tooltip>
                              }
                            >
                              <svg
                                width="18"
                                height="18"
                                viewBox="0 0 512 512"
                                fill="#999"
                                className="mt-1"
                              >
                                <g>
                                  <g>
                                    <path
                                      d="M256,0C114.509,0,0,114.496,0,256c0,141.489,114.496,256,256,256c141.491,0,256-114.496,256-256
                                                                                                                                          C512,114.509,397.504,0,256,0z M256,476.279c-121.462,0-220.279-98.816-220.279-220.279S134.538,35.721,256,35.721
                                                                                                                                          c121.463,0,220.279,98.816,220.279,220.279S377.463,476.279,256,476.279z"
                                    ></path>
                                  </g>
                                </g>
                                <g>
                                  <g>
                                    <path
                                      d="M248.425,323.924c-14.153,0-25.61,11.794-25.61,25.946c0,13.817,11.12,25.948,25.61,25.948
                                                                                                                                          c14.49,0,25.946-12.131,25.946-25.948C274.371,335.718,262.577,323.924,248.425,323.924z"
                                    ></path>
                                  </g>
                                </g>
                                <g>
                                  <g>
                                    <path
                                      d="M252.805,127.469c-45.492,0-66.384,26.959-66.384,45.155c0,13.142,11.12,19.208,20.218,19.208
                                                                                                                                          c18.197,0,10.784-25.948,45.155-25.948c16.848,0,30.328,7.414,30.328,22.915c0,18.196-18.871,28.642-29.991,38.077
                                                                                                                                          c-9.773,8.423-22.577,22.24-22.577,51.22c0,17.522,4.718,22.577,18.533,22.577c16.511,0,19.881-7.413,19.881-13.817
                                                                                                                                          c0-17.522,0.337-27.631,18.871-42.121c9.098-7.076,37.74-29.991,37.74-61.666S295.937,127.469,252.805,127.469z"
                                    ></path>
                                  </g>
                                </g>
                              </svg>
                            </OverlayTrigger>
                          </div>
                        </td>
                        <td>
                          <SliderComponentSingle
                            value={thresholdData?.FullAfter}
                            min={0}
                            max={thresholdData?.TotalParkingCount + 1}
                            marks={thresholdData?.Marks}
                            categoryType={thresholdData?.ThresholdId}
                            submitHandler={(
                              event: Event,
                              value: number | number[]
                            ) => {
                              handleSliderChange(event, value);
                            }}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Modal.Body>
              <div className="modal-footer">
                <div className="row tab-action g-0">
                  <div className="col-12">
                    <Button
                      variant="light-danger"
                      type="button"
                      onClick={props.onModalClosed}
                      disabled={props.isLoading}
                    >
                      <FormattedMessage id="button_cancel" />
                    </Button>

                    {isOperationPermittedParkingRoleBase(
                      ModuleType.ParkingManagementButtons,
                      "Add"
                    ) && (
                      <LoaderButton
                        type="submit"
                        isLoading={props.isLoading}
                        text="button_save"
                      />
                    )}
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default AddUpdateIntegratorThresholdForm;
