/*    
<summary>
   This component is Managing Parking lots & performing CRUD operations on the parking-lots.
   CRUD (Create, Read, Update, Delete)
   Developer: Mohammad Saquib Khan, Created Date:14-April-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns JSX</returns>
*/

import { observer } from "mobx-react";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import toast from "react-hot-toast";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import SlidingPanel from "react-sliding-side-panel";
import noMessageIcon from "../../assets/Images/svg/018-empty.svg";
import { ModuleType } from "../../constants/enums/module-type-enum";
import { ParkingLotInputType } from "../../constants/enums/parking-lot-input-type-enum";
import { parkingLotStatus } from "../../constants/enums/parking-lot-status-enum";
import userSettingEnum from "../../constants/enums/user-setting-enum";
import { parkingLotStatusOptions } from "../../constants/options.constant";
import RoutesConstants from "../../constants/routes.constant";
import { useStore } from "../../contexts/store-provider";
import {
  isOperationPermittedParkingRoleBase,
  isOperationPermittedRoleBase,
} from "../../helpers/action-permission-helper";
import {
  getIsTenantAccessFromLocalStorage,
  getTenantAccessFromLocalStorage,
} from "../../helpers/localstorage-helper";
import IPageInfo, { IPageLimitInfo } from "../../models/ICommon";
import LoaderButton from "../../shared-components/button/loader-button";
import FormLoader from "../../shared-components/FormLoader/FormLoader";
import Grid from "../../shared-components/Grid/Grid";
import { CustomParkingLotActionFormatter } from "../../shared-components/Grid/GridFormatter";
import Pagination from "../../shared-components/Grid/Pagination";
import Label from "../../shared-components/label/label";
import ConfirmPopup from "../../shared-components/popup/confirm/confirm-popup";
import ConfirmPopupHOC from "../../shared-components/popup/confirm/confirm-popup-hoc";
import DeletePopup from "../../shared-components/popup/delete/delete-popup";
import DeletePopupHOC from "../../shared-components/popup/delete/delete-popup-hoc";
import { formatMessage } from "../../translations/format-message";
import AddEditLinkbaseInThreshold from "../linkbase-in-threshold/components/add-edit-linkbase-in-threshold";
import AddLinkbaseInThresholdPopupHOC from "../linkbase-in-threshold/hoc/AddLinkbaseInThresholdPopupHOC";
import AddUpdateLinkbaseIn from "../linkbase-in/components/add-update-linkbase-in";
import AddLinkbaseInPopupHOC from "../linkbase-in/hoc/AddUpdateLinkbaseInPopupHOC";
import AddParkingLot from "../parking-lot/components/add-edit-parking-lot";
import AddEditImageStatus from "../status-image/components/add-edit-image-status";
import AddStatusImagesPopupHOC from "../status-image/hoc/AddStatusImagesPopupHOC";
import AddEditThreshold from "../threshold/components/add-edit-threshold";
import AddThresholdPopupHOC from "../threshold/hoc/AddThresholdPopupHOC";
import AddParkingLotPopupHOC from "./hoc/AddParkingLotPopupHOC";
import ParkingLotDetails from "./parking-lot-details";
import "./parking-lot.css";

const ParkingLot = (props: any) => {
  const history = useHistory();
  const { parkingLotStore, preferencesStore, authStore, linkbaseStore } =
    useStore();
  const {
    inProgress,
    error,
    GetParkingLotListService,
    allParkingLotList,
    parkingLotList,
    resetAddUpdateParkingLotState,
    addUpdateParkingLotState,
    resetParkingLotDetail,
    deleteParkingLotState,
    DeleteParkingLotService,
    resetDeleteParkingLot,
    setParkingLotThresholdState,
    resetSetThresholdParkingLotState,
    UpdateParkingLotStatusService,
    updateParkingLotStatusState,
    resetUpdateParkingLotStatus,
    GetParkingLotFilterListService,
  } = parkingLotStore;
  const {
    userSetting,
    setUserSetting,
    islanguageChanged,
    updateLanguageChangedFlag,
    getTenantAccess,
    setManagePriceSchedulerAccess,
    setParkingLotCameraAccess,
    setTenantAccess,
    resetUnwantedModules,
    getParkingLotFilter,
    setParkingFilter,
    setManageLinkbaseOutAccess,
  } = preferencesStore;
  const { deleteLinkbaseInState } = linkbaseStore;
  const { getUserType } = authStore;
  let lang: string;
  const allParkingLots: Array<any> = allParkingLotList;
  const pageLimitOptions: Array<number> = [10, 50, 100];
  const [selectedId, setSelectedId] = useState<number>(0);
  const [currentStatus, setCurrentStatus] = useState<number>(0);
  const [openPanel, setOpenPanel] = useState(false);
  const [disablePagination, setDisablePagination] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [selectedParkingLotId, setSelectedParkingLotId] = useState<string>("");

  const [filter, setFilter] = useState<any>(getParkingLotFilter.IsFilterActive);
  const [parkingLotName, setParkingLotName] = useState(
    getParkingLotFilter.ParkingLotName
  );
  const [branchName, setBranchName] = useState(
    getParkingLotFilter.BranchName
  );
  const [parkingLotId, setParkingLotId] = useState(
    getParkingLotFilter.ParkingLotId
  );
  const [parkingStatus, setParkingStatus] = useState(
    getParkingLotFilter.Status
  );

  const [redirectedFromBranch, setRedirectedFromBranch] = useState<string>(
    props.location.state ? props.location.state?.BranchName : ""
  );

  const [isRedirected, setIsRedirected] = useState<boolean>(
    props.location.state ? props.location.state?.IsRedirected : false
  );

  const handleParkingLotName = (event: any) => {
    event.target.value = event.target.value;
    setParkingLotName(event.target.value);
  };

  const handleBranchName = (event: any) => {
    event.target.value = event.target.value;
    setBranchName(event.target.value);
  };

  const handleParkingLotId = (event: any) => {
    event.target.value = event.target.value;
    setParkingLotId(event.target.value);
  };

  const handleParkingStatus = (event: any) => {
    setParkingStatus(Number(event.target.value));
  };

  const [pageLimitInfo, setPageLimit] = useState<IPageLimitInfo>({
    pageLimit: userSetting.pageLimitConfig,
    isPageLimitChange: false,
  });

  const [pageInfo, setPageInfo] = useState<IPageInfo>({
    currentPage: 1,
    totalPages: 1,
    isPagerChange: false,
    isRefreshPage: false,
  });

  const onPageChanged = (pageNumber: number) => {
    closePanelhandler();
    setPageInfo({ ...pageInfo, currentPage: pageNumber, isPagerChange: true });
  };

  const setIsRefreshPage = () => {
    setPageInfo({ ...pageInfo, isRefreshPage: false });
  };

  const onPageLimitChanged = (evt: any) => {
    closePanelhandler();
    let pages: number;
    pages = props.data && Math.ceil(props.data.length / evt.target.value);
    setPageInfo({
      ...pageInfo,
      currentPage: 1,
      totalPages: pages,
      isPagerChange: false,
    });
    setPageLimit({
      pageLimit: parseInt(evt.target.value),
      isPageLimitChange: true,
    });
    setUserSetting(userSettingEnum.UserLimitConfig, parseInt(evt.target.value));
  };

  /**
   * This function calls the "getAllParkingLots" store funtion that get configuration list from API
   */
  const callGetParkingLotListService = () => {
    if (isRedirected == true) {
      GetParkingLotFilterListService(
        pageInfo.currentPage,
        pageLimitInfo.pageLimit,
        getTenantAccessFromLocalStorage(),
        getParkingLotFilter.ParkingLotName,
        redirectedFromBranch,
        getParkingLotFilter.ParkingLotId,
        getParkingLotFilter.Status
      );
    } else {
      getParkingLotFilter.IsFilterActive == true
        ? GetParkingLotFilterListService(
            pageInfo.currentPage,
            pageLimitInfo.pageLimit,
            getTenantAccessFromLocalStorage(),
            getParkingLotFilter.ParkingLotName,
            getParkingLotFilter.BranchName,
            getParkingLotFilter.ParkingLotId,
            getParkingLotFilter.Status
          )
        : GetParkingLotListService(
            pageInfo.currentPage,
            pageLimitInfo.pageLimit,
            getTenantAccessFromLocalStorage()
          );
    }
  };

  useEffect(() => {
    if (getParkingLotFilter.IsFilterActive) callGetParkingLotListService();
    else callGetParkingLotListService();
  }, [getParkingLotFilter]);

  useEffect(() => {
    if (isRedirected == true) {
      setFilter(true);
      setBranchName(redirectedFromBranch);
      if (redirectedFromBranch?.trim() !== "") {
        debugger
        setFilterState({
          ParkingLotName: "",
          ParkingLotId: "",
          BranchName: redirectedFromBranch,
          Status: "",
          IsFilterActive: true,
        });
      }
    }
  }, [isRedirected]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "language" of the useEffect changed.
   */
  useEffect(() => {
    if (islanguageChanged) {
      callGetParkingLotListService();
      updateLanguageChangedFlag();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [islanguageChanged]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "ParkingLotList" of the useEffect changed.
   */
  useEffect(() => {
    setPageInfo({
      ...pageInfo,
      totalPages: parkingLotList?.PagingDetails?.TotalPages,
      isPagerChange: false,
    });
    setDisablePagination(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parkingLotList]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "pageInfo.currentPage" of the useEffect changed.
   */
  useEffect(() => {
    if (pageInfo.isPagerChange) {
      callGetParkingLotListService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageInfo.currentPage]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addUpdateParkingLotState.success" of the useEffect changed.
   */
  useEffect(() => {
    if (addUpdateParkingLotState.success) {
      callGetParkingLotListService();
      resetAddUpdateParkingLotState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUpdateParkingLotState.success]);

  useEffect(() => {
    if (setParkingLotThresholdState.success) {
      callGetParkingLotListService();
      resetSetThresholdParkingLotState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setParkingLotThresholdState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "deleteParkingLotState.success" of the useEffect changed.
   */
  useEffect(() => {
    if (deleteParkingLotState.success) {
      toast.success(formatMessage("delete_success"));
      props.deletePopupToggleHandler();
      resetDeleteParkingLot();
      if (allParkingLotList?.length == 1) {
        if (pageInfo.currentPage > 1)
          setPageInfo({
            ...pageInfo,
            currentPage: 1,
            isRefreshPage: true,
            isPagerChange: true,
          });
        else {
          callGetParkingLotListService();
        }
      } else {
        callGetParkingLotListService();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteParkingLotState.success]);

  useEffect(() => {
    if (updateParkingLotStatusState.success) {
      toast.success(formatMessage("updated_success"));
      props.confirmPopupToggleHandler();
      callGetParkingLotListService();
      resetUpdateParkingLotStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateParkingLotStatusState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "deleteParkingLotState.error" of the useEffect changed.
   */
  useEffect(() => {
    if (deleteParkingLotState.error) {
      props.deletePopupToggleHandler();
      callGetParkingLotListService();
      resetDeleteParkingLot();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteParkingLotState.error]);

  /**
   * This function shows the add ParkingLot pop up from where we can add new and update existing ParkingLot. we use id to identify
   * that we are adding or updating ParkingLot.
   * "Id == -1" : we are adding new ParkingLot
   * "Id != -1" : means we are updating existing ParkingLot
   */
  const openAddParkingLotHandler = (
    row: { PlId: number },
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    closePanelhandler();
    setSelectedId(row.PlId);
    event.stopPropagation();
    props.addParkingLotToggleHandler();
  };

  const openAddThresholdHandler = (
    row: any,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    closePanelhandler();
    setSelectedId(row.PlId);
    event.stopPropagation();
    if (row.ParkingLotInputTypeEnum == ParkingLotInputType.LinkBaseIn) {
      props.addLinkbaseInThresholdToggleHandler();
    } else {
      props.addThresholdToggleHandler();
    }
  };

  const openAddImageStatusHandler = (
    row: { PlId: number },
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    closePanelhandler();
    setSelectedId(row.PlId);
    event.stopPropagation();
    props.addStatusImagesToggleHandler();
  };

  const openAccessCameraManagement = (
    row: any,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    var plName: string = row.PlName;
    event.stopPropagation();
    closePanelhandler();
    setParkingLotCameraAccess({
      plId: row.PlId,
      parkingLotName: row.PlName,
      isAccessCameraActive: true,
    });
    history.push({
      pathname: RoutesConstants.CameraManagement,
      state: plName,
    });
    setSelectedId(row.PlId);
  };

  const openAccessPriceScheduler = (
    row: any,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    var plName: string = row.PlName;
    event.stopPropagation();
    closePanelhandler();
    setManagePriceSchedulerAccess({
      plId: row.PlId,
      parkingLotName: row.PlName,
      isAccessPriceSchedulerActive: true,
    });
    history.push({
      pathname: RoutesConstants.PriceScheduler,
      state: plName,
    });
    setSelectedId(row.PlId);
  };

  const openAccessLinkBaseIn = (
    row: any,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    closePanelhandler();
    var plName: string = row.PlName;
    event.stopPropagation();
    setSelectedParkingLotId(row.ParkingLotId);
    setSelectedId(row.PlId);
    props.addLinkbaseInToggleHandler();
  };

  const openAccessLinkBaseOut = (
    row: any,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    var plName: string = row.PlName;
    history.push({
      pathname: RoutesConstants.LinkbaseOut,
      state: plName,
    });
    closePanelhandler();
    event.stopPropagation();
    setManageLinkbaseOutAccess({
      plId: row.PlId,
      parkingLotId: row.ParkingLotId,
      parkingLotName: row.PlName,
      isLinkBaseOutAccess: true,
    });
    setSelectedId(row.PlId);
  };

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "pageLimitInfo.pageLimit" of the useEffect changed.
   */
  useEffect(() => {
    if (pageLimitInfo.isPageLimitChange) {
      callGetParkingLotListService();
      setPageLimit({ ...pageLimitInfo, isPageLimitChange: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageLimitInfo.pageLimit]);

  /**
   * This function deletes the ParkingLot by providing Id to the Api
   */
  const deleteHandler = () => {
    DeleteParkingLotService(selectedId, getTenantAccessFromLocalStorage());
  };

  const parkingLotChangeStatusHandler = () => {
    let obj = {
      TenantId: getTenantAccessFromLocalStorage(),
      PlId: selectedId,
      CurrentStatus:
        currentStatus == parkingLotStatus.Available ||
        currentStatus == parkingLotStatus.Crowded ||
        currentStatus == parkingLotStatus.Full
          ? parkingLotStatus.Closed
          : parkingLotStatus.Available,
    };
    UpdateParkingLotStatusService(obj);
  };

  /**
   * This function shows the delete ParkingLot confirmation popup component and sets the seleted ParkingLot Id
   */
  const deleteClickHandler = (
    row: { PlId: number },
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    closePanelhandler();
    setSelectedId(row.PlId);
    event.stopPropagation();
    props.deletePopupToggleHandler();
  };

  const changeParkingLotStatusHandler = (
    row: any,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    closePanelhandler();
    setSelectedId(row.PlId);
    setCurrentStatus(row.CurrentStatus);
    event.stopPropagation();
    props.confirmPopupToggleHandler();
  };

  useEffect(() => {
    if (deleteLinkbaseInState.success) {
      callGetParkingLotListService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteLinkbaseInState.success]);

  /**
   * This function shows the change password popup component and sets the seleted ParkingLot Id
   */
  const changePasswordClickHandler = (row: { PlId: number }) => {
    setSelectedId(row.PlId);
    props.changePasswordToggleHandler();
  };

  /**
   * This function provides manual refresh functionality
   */
  const refreshClickHandler = () => {
    callGetParkingLotListService();
  };

  function rowClassFormat(row: any, rowIdx: number) {
    let className: string = "";
    if (selectedRow?.PlId && row.PlId === selectedRow.PlId) {
      className = "bg-row SelectedRow";
    }
    return className;
  }

  const rowEvents = (e: SyntheticEvent, row: any, rowIndex: number) => {
    setSelectedRow(row);
    setOpenPanel(true);
  };

  const closePanelhandler = () => {
    setSelectedRow({});
    setOpenPanel(false);
    resetParkingLotDetail();
  };

  const exitTenantHandler = () => {
    setTenantAccess({
      isAccessTenantActive: false,
      tenantId: 0,
      tenantName: "",
      orgId: "",
    });
    resetUnwantedModules();
    history.push(RoutesConstants.TenantManagement);
  };

  const setFilterState = (data: any) => {
    setFilter(data.IsFilterActive);
    setParkingLotName(data.ParkingLotName);
    setBranchName(data.BranchName);
    setParkingLotId(data.ParkingLotId);
    setParkingStatus(data.Status);
    setParkingFilter(data);
  };

  /**
   * The column constant defines the column description for the user grid (table)
   */
  const columns: any = [
    {
      dataField: "PlId",
      text: formatMessage("label_id"),
      hidden: true,
    },
    {
      dataField: "PlName",
      text: formatMessage("parking_lot_name"),
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "ParkingLotId",
      text: formatMessage("parking_lot_id"),
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "Branch.BranchName",
      text: formatMessage("branch_name"),
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "Comment",
      classes: "address-Tenant",
      text: formatMessage("comment"),
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? cell : undefined;
      },
    },
    // {
    //   dataField: "CurrentStatus",
    //   text: formatMessage("parking_lot_status"),
    //   formatter: (cell: any, row: any) => (
    //     <span>{formatMessage(parkingLotStatus[row.CurrentStatus])}</span>
    //   ),
    // },
    {
      dataField: "CurrentStatus",
      classes: "btn-light-danger parking-status",
      text: formatMessage("parking_lot_status"),
      formatter: (cell: any, row: any) => (
        <span
          className={`badge badge-parking ${
            row.CurrentStatus == parkingLotStatus.Available
              ? "badge-light-success"
              : row.CurrentStatus == parkingLotStatus.Closed
              ? "badge-light-dark"
              : row.CurrentStatus == parkingLotStatus.Crowded
              ? "badge-light-secondary"
              : row.CurrentStatus == parkingLotStatus.Full
              ? "badge-light-danger"
              : ""
          }`}
        >
          {row.CurrentStatus != -1 && (
            <FormattedMessage id={parkingLotStatus[row.CurrentStatus]} />
          )}
        </span>
      ),
    },

    {
      dataField: "CurrentStatus",
      classes: "text-center open-close",
      text: formatMessage("open_close"),
      hidden: isOperationPermittedParkingRoleBase(
        ModuleType.ParkingManagementButtons,
        "Hide"
      ),
      formatter: CustomParkingLotActionFormatter([
        {
          dataField: "isParkingLotOpenClosed",
          handler: changeParkingLotStatusHandler,
          icon2: (
            <svg
              fill="rgb(134, 137, 144)"
              version="1.1"
              width="20"
              height="20"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 405.272 405.272"
            >
              <g>
                <path
                  d="M393.401,124.425L179.603,338.208c-15.832,15.835-41.514,15.835-57.361,0L11.878,227.836
                c-15.838-15.835-15.838-41.52,0-57.358c15.841-15.841,41.521-15.841,57.355-0.006l81.698,81.699L336.037,67.064
                c15.841-15.841,41.523-15.829,57.358,0C409.23,82.902,409.23,108.578,393.401,124.425z"
                />
              </g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
            </svg>
          ),
          icon1: (
            <svg
              fill="rgb(239, 40, 65)"
              version="1.1"
              width="20"
              height="20"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 405.272 405.272"
            >
              <g>
                <path
                  d="M393.401,124.425L179.603,338.208c-15.832,15.835-41.514,15.835-57.361,0L11.878,227.836
                c-15.838-15.835-15.838-41.52,0-57.358c15.841-15.841,41.521-15.841,57.355-0.006l81.698,81.699L336.037,67.064
                c15.841-15.841,41.523-15.829,57.358,0C409.23,82.902,409.23,108.578,393.401,124.425z"
                />
              </g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
            </svg>
          ),
          isDisable: true,
          title1: "close_parking_lot",
          title2: "open_parking_lot",
          title: "image_status_title",
          varient: "primary",
          action: "allAccess",
          hidden: isOperationPermittedParkingRoleBase(
            ModuleType.ParkingManagementButtons,
            "Hide"
          ),
        },
      ]),
    },

    {
      dataField: "",
      formatter: CustomParkingLotActionFormatter([
        {
          dataField: "isLinkbaseIn",
          handler: openAccessLinkBaseIn,
          icon1: (
            <svg
              id="Layer_2"
              data-name="Layer 2"
              fill="#0091ff"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24.18 25.26"
            >
              <g id="_レイヤー_3" data-name="レイヤー 3">
                <g>
                  <rect x="9.25" y="18.97" width="5.68" height="6.29" />
                  <polygon points="12.09 13.93 15.19 16.83 18.3 19.73 12.09 19.73 5.88 19.73 8.99 16.83 12.09 13.93" />
                  <g>
                    <path d="M9.67,5.84v5.66h-1.2V5.84h1.2Z" />
                    <path d="M11.87,5.84l2.48,3.85v-3.85h1.19v5.66h-1.32l-2.48-3.85v3.85h-1.19V5.84h1.32Z" />
                  </g>
                  <path d="M20.89,0H3.29C1.48,0,0,1.48,0,3.29V20.89c0,1.81,1.48,3.29,3.29,3.29h2.16v-2.54H3.29c-.42,0-.75-.34-.75-.76V3.29c0-.42,.34-.75,.75-.75H20.89c.42,0,.75,.34,.75,.75V20.89c0,.42-.34,.76-.75,.76h-2.16v2.54h2.16c1.81,0,3.29-1.48,3.29-3.29V3.29c0-1.81-1.48-3.29-3.29-3.29Z" />
                </g>
              </g>
            </svg>
          ),
          isDisable: true,
          title: "linkbase_in",
          varient: "primary",
          action: "view",
          hidden: isOperationPermittedParkingRoleBase(
            ModuleType.ParkingManagementButtons,
            "Hide"
          ),
        },
        {
          dataField: "isLinkbaseOut",
          handler: openAccessLinkBaseOut,
          icon1: (
            <svg
              id="Layer_2"
              data-name="Layer 2"
              fill="#0091ff"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24.18 25.26"
            >
              <g id="_レイヤー_3" data-name="レイヤー 3">
                <g>
                  <rect x="9.25" y="13.93" width="5.68" height="6.29" />
                  <polygon points="12.09 25.26 8.99 22.36 5.88 19.45 12.09 19.45 18.3 19.45 15.19 22.36 12.09 25.26" />
                  <g>
                    <path d="M6.86,5.73c1.61,0,2.92,1.19,2.92,2.94s-1.32,2.94-2.92,2.94-2.92-1.17-2.92-2.94,1.32-2.94,2.92-2.94Zm0,4.79c1.07,0,1.73-.91,1.73-1.85s-.66-1.85-1.73-1.85-1.73,.91-1.73,1.85,.66,1.85,1.73,1.85Z" />
                    <path d="M15.14,5.84v3.27c0,.57,0,2.5-2.43,2.5s-2.43-1.94-2.43-2.49v-3.28h1.19v3.22c0,.51,0,1.46,1.24,1.46s1.24-.94,1.24-1.46v-3.22h1.19Z" />
                    <path d="M18.38,11.5h-1.19V6.93h-1.71v-1.09h4.61v1.09h-1.71v4.57Z" />
                  </g>
                  <path d="M20.89,0H3.29C1.48,0,0,1.48,0,3.29V20.89c0,1.81,1.48,3.29,3.29,3.29h2.16v-2.54H3.29c-.42,0-.75-.34-.75-.76V3.29c0-.42,.34-.75,.75-.75H20.89c.42,0,.75,.34,.75,.75V20.89c0,.42-.34,.76-.75,.76h-2.16v2.54h2.16c1.81,0,3.29-1.48,3.29-3.29V3.29c0-1.81-1.48-3.29-3.29-3.29Z" />
                </g>
              </g>
            </svg>
          ),
          isDisable: true,
          title: "linkbase_out",
          varient: "primary",
          action: "view",
          hidden: isOperationPermittedParkingRoleBase(
            ModuleType.ParkingManagementButtons,
            "Hide"
          ),
        },
        {
          dataField: "isThreshold",
          handler: openAddThresholdHandler,
          icon1: (
            <svg
              id="Layer_2"
              width="30"
              height="30"
              data-name="Layer 2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 25.52 25.81"
            >
              <g id="_レイヤー_3" data-name="レイヤー 3">
                <g>
                  <rect
                    fill="#0091ff"
                    x="3.69"
                    y="11.24"
                    width="10.72"
                    height="7.25"
                  />
                  <rect
                    fill="#0091ff"
                    x="13.39"
                    y="2.93"
                    width="2.03"
                    height="22.89"
                  />
                  <path
                    fill="#0091ff"
                    d="M2.64,4.43l-.41,1.23H.92L2.98,0h1.49l2.06,5.66h-1.31l-.41-1.23H2.64Zm1.82-1.01l-.74-2.23-.75,2.23h1.49Z"
                  />
                  <path
                    fill="#0091ff"
                    d="M22.63,0c.42,0,.75,.07,.97,.16,.49,.2,.88,.66,.88,1.32,0,.7-.37,1.01-.58,1.19,.23,.15,.75,.49,.75,1.37,0,1.61-1.56,1.61-2.07,1.61h-1.99V0h2.05Zm-.86,1.09v1.15h.87c.54,0,.65-.4,.65-.58,0-.2-.1-.57-.71-.57h-.81Zm0,2.13v1.35h.87c.18,0,.82,0,.82-.67,0-.5-.37-.68-.75-.68h-.94Z"
                  />
                  <path
                    fill="#0091ff"
                    d="M23.35,22.4H2.18c-1.2,0-2.18-.98-2.18-2.18V9.5c0-1.2,.98-2.18,2.18-2.18H23.35c1.2,0,2.18,.98,2.18,2.18v10.72c0,1.2-.98,2.18-2.18,2.18Zm-.06-12.9l-21.11,.06,.06,10.66,21.05-.06V9.5Z"
                  />
                </g>
              </g>
            </svg>
          ),
          isDisable: true,
          title: "threshold_management",
          varient: "primary",
          action: "allAccess",
          hidden: isOperationPermittedParkingRoleBase(
            ModuleType.ParkingManagementButtons,
            "Hide"
          ),
        },
        {
          dataField: "isPriceScheduler",
          handler: openAccessPriceScheduler,
          icon1: (
            <svg
              id="Layer_2"
              fill="#0091ff"
              data-name="Layer 2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 26.58 22.54"
            >
              <g id="_レイヤー_3" data-name="レイヤー 3">
                <g>
                  <path d="M19.32,8.01c-4.01,0-7.27,3.25-7.27,7.27s3.25,7.27,7.27,7.27,7.27-3.25,7.27-7.27-3.25-7.27-7.27-7.27Zm5,8.5h-6.35v-6.91h1.66v5.23h4.69v1.68Z" />
                  <path d="M9.61,18.16H2.84c-1.57,0-2.84-1.27-2.84-2.84V2.84C0,1.27,1.27,0,2.84,0H19.6c1.57,0,2.85,1.27,2.85,2.84v3.51h-2.24V2.84c0-.33-.27-.6-.61-.6H2.84c-.33,0-.6,.27-.6,.6V15.31c0,.33,.27,.6,.6,.6h6.77v2.24Z" />
                  <rect
                    x="4.31"
                    y="4.77"
                    width="2.52"
                    height="2.52"
                    rx=".46"
                    ry=".46"
                  />
                  <rect
                    x="8.35"
                    y="4.77"
                    width="2.52"
                    height="2.52"
                    rx=".46"
                    ry=".46"
                  />
                  <rect
                    x="4.31"
                    y="9.02"
                    width="2.52"
                    height="2.52"
                    rx=".46"
                    ry=".46"
                  />
                  <rect
                    x="8.35"
                    y="9.02"
                    width="2.52"
                    height="2.52"
                    rx=".46"
                    ry=".46"
                  />
                  <rect
                    x="12.39"
                    y="4.77"
                    width="2.52"
                    height="2.52"
                    rx=".46"
                    ry=".46"
                  />
                </g>
              </g>
            </svg>
          ),
          isDisable: true,
          title: "price_scheduler",
          varient: "primary",
          action: "view",
          hidden: isOperationPermittedParkingRoleBase(
            ModuleType.PriceSchedular,
            "Hide"
          ),
        },
        {
          dataField: "isCamera",
          handler: openAccessCameraManagement,
          icon1: (
            <svg
              id="Layer_2"
              data-name="Layer 2"
              fill="#0091ff"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 27.01 25.88"
            >
              <g id="_レイヤー_3" data-name="レイヤー 3">
                <g>
                  <path d="M4.63,6.45l1.43,.4c0,.43,.05,.84,.16,1.25l-1.29,.73c-.23,.13-.3,.41-.18,.64l.84,1.48,3.93-2.23c-.69-.61-1.03-1.57-.77-2.52,.36-1.32,1.73-2.09,3.04-1.72,.95,.26,1.61,1.04,1.77,1.95l3.93-2.23-.84-1.48c-.13-.23-.41-.3-.64-.18l-1.29,.73c-.29-.3-.62-.56-.99-.77l.4-1.43c.07-.25-.08-.51-.33-.58L12.12,.02c-.25-.07-.51,.08-.58,.33l-.4,1.43c-.43,0-.84,.05-1.25,.16l-.73-1.29c-.13-.23-.41-.3-.64-.18l-1.53,.87c-.23,.13-.3,.41-.18,.64l.73,1.29c-.3,.29-.56,.62-.77,.99l-1.43-.4c-.25-.07-.51,.08-.58,.33l-.47,1.69c-.07,.25,.08,.51,.33,.58Z" />
                  <path d="M5.47,17.53l-4.47-.68c-.64-.1-1.21,.58-.92,1.1l4.31,7.61c.29,.52,1.17,.38,1.41-.23l1.72-4.18c.08-.19,.07-.4-.02-.56l-1.56-2.75c-.09-.17-.26-.28-.47-.31Z" />
                  <rect
                    x="5.85"
                    y="16.05"
                    width="2.55"
                    height="5.85"
                    rx=".24"
                    ry=".24"
                    transform="translate(-8.43 5.98) rotate(-29.53)"
                  />
                  <path d="M20.63,6.51l4.15,7.29-12.75,7.25-4.17-7.31,12.76-7.23m0-2.25c-.37,0-.75,.09-1.09,.29L6.77,11.79c-1.07,.6-1.44,1.96-.84,3.03l4.16,7.34c.41,.72,1.16,1.13,1.93,1.13,.37,0,.75-.09,1.09-.29l12.78-7.24c1.07-.6,1.44-1.96,.84-3.03l-4.16-7.34c-.41-.72-1.16-1.13-1.93-1.13h0Z" />
                  <polygon points="12.79 18.3 12.26 17.36 21.75 11.99 22.28 12.92 12.79 18.3" />
                </g>
              </g>
            </svg>
          ),
          isDisable: true,
          title: "camera_title",
          varient: "primary",
          action: "view",
          hidden: isOperationPermittedParkingRoleBase(
            ModuleType.CameraManagement,
            "Hide"
          ),
        },
        {
          dataField: "isImage",
          handler: openAddImageStatusHandler,
          icon1: (
            <svg
              fill="#0091ff"
              id="Layer_2"
              data-name="Layer 2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 25.34 24.09"
            >
              <g id="_レイヤー_3" data-name="レイヤー 3">
                <g>
                  <g>
                    <path d="M7.49,7.03v2.61c-2.22,.48-3.88,2.45-3.88,4.81,0,.84,.22,1.66,.63,2.39l-1.87,1.87c-.87-1.25-1.34-2.72-1.34-4.26,0-3.78,2.81-6.91,6.45-7.42m1.04-1.11C3.82,5.91,0,9.73,0,14.44c0,2.25,.88,4.29,2.3,5.81l3.3-3.3c-.58-.68-.94-1.55-.94-2.51,0-2.14,1.74-3.88,3.88-3.88V5.91h0Z" />
                    <g>
                      <path d="M9.51,5.97v4.73c1.67,.43,2.9,1.94,2.9,3.74,0,2.14-1.74,3.88-3.88,3.88-.77,0-1.48-.25-2.08-.64l-3.33,3.33c1.47,1.22,3.35,1.96,5.41,1.96,4.71,0,8.53-3.82,8.53-8.53,0-4.38-3.3-7.99-7.56-8.47Z" />
                      <path d="M9.51,5.97v4.73c1.67,.43,2.9,1.94,2.9,3.74,0,2.14-1.74,3.88-3.88,3.88-.77,0-1.48-.25-2.08-.64l-3.33,3.33c1.47,1.22,3.35,1.96,5.41,1.96,4.71,0,8.53-3.82,8.53-8.53,0-4.38-3.3-7.99-7.56-8.47h0Z" />
                    </g>
                  </g>
                  <path d="M22.67,24.09h-6.36v-2.24h6.36c.23,0,.43-.19,.43-.42V7.13l-4.6-4.89h-5.75c-.23,0-.42,.19-.42,.43v1.72h-2.24v-1.72c0-1.47,1.2-2.67,2.66-2.67h6.72l5.86,6.24v15.19c0,1.47-1.2,2.66-2.67,2.66Z" />
                  <path d="M24.22,7.76h-4.4c-1.37,0-2.48-1.11-2.48-2.48V1.12h2.24V5.27c0,.13,.11,.24,.24,.24h4.4v2.24Z" />
                </g>
              </g>
            </svg>
          ),
          isDisable: true,
          title: "image_status_title",
          varient: "primary",
          action: "allAccess",
          hidden: isOperationPermittedParkingRoleBase(
            ModuleType.ParkingManagementButtons,
            "Hide"
          ),
        },
        {
          dataField: "isEdit",
          handler: openAddParkingLotHandler,
          icon1: (
            <svg
              id="Layer_2"
              fill="#0091ff"
              width="21"
              height="21"
              data-name="Layer 2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 28.43 24.75"
            >
              <g id="_レイヤー_3" data-name="レイヤー 3">
                <g>
                  <path d="M10.53,15.53l1.19,1.19,1.19,1.19-1.85,.58-1.61,.5,.5-1.61,.58-1.85m-.45-1.81l-1.05,3.37-1.05,3.37,3.37-1.05,3.37-1.05-2.32-2.32-2.32-2.32h0Z" />
                  <polygon points="7.97 20.46 8.57 18.54 9.17 16.62 10.49 17.94 11.81 19.26 9.89 19.86 7.97 20.46" />
                  <path d="M15.22,19.37l-6.15-6.15L22.28,0l6.15,6.15-13.21,13.21Zm-3.12-6.15l3.12,3.12L25.4,6.15l-3.12-3.12L12.1,13.21Z" />
                  <path d="M23.57,24.75H4.47c-2.46,0-4.47-2-4.47-4.47v-6.38c0-2.46,2-4.47,4.47-4.47h2.36v2.24h-2.36c-1.23,0-2.23,1-2.23,2.23v6.38c0,1.23,1,2.23,2.23,2.23H23.57c1.23,0,2.23-1,2.23-2.23v-7.47h2.24v7.47c0,2.46-2,4.47-4.47,4.47Z" />
                  <rect
                    x="13.01"
                    y="8.8"
                    width="11.25"
                    height="1.3"
                    transform="translate(-1.22 15.95) rotate(-45)"
                  />
                </g>
              </g>
            </svg>
          ),
          isDisable: true,
          title: "edit",
          varient: "primary",
          action: "edit",
          hidden: isOperationPermittedParkingRoleBase(
            ModuleType.ParkingManagementButtons,
            "Hide"
          ),
        },
        {
          dataField: "isDelete",
          handler: deleteClickHandler,
          icon1: (
            <svg
              id="Layer_2"
              fill="#0091ff"
              width="18"
              height="18"
              data-name="Layer 2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20.09 20.66"
            >
              <g id="_レイヤー_3" data-name="レイヤー 3">
                <g>
                  <polygon points="12.12 1.99 12.12 0 10.05 0 7.97 0 7.97 1.99 0 1.99 0 4.07 10.05 4.07 20.09 4.07 20.09 1.99 12.12 1.99" />
                  <path d="M14.29,17.76c-.02,.37-.32,.66-.69,.66H6.49c-.37,0-.67-.29-.69-.65l-.53-12.44-2.24,.09,.53,12.45c.08,1.56,1.37,2.78,2.93,2.78h7.11c1.56,0,2.85-1.22,2.93-2.79l.53-12.44-2.24-.09-.53,12.43Z" />
                  <rect x="11.68" y="6.8" width="1.16" height="9.8" />
                </g>
              </g>
            </svg>
          ),
          isDisable: true,
          title: "delete",
          action: "delete",
          varient: "danger",
          hidden: isOperationPermittedParkingRoleBase(
            ModuleType.ParkingManagementDeleteButton,
            "Hide"
          ),
        },
      ]),
      text: formatMessage("actions"),
      classes: "last-column",
      // hidden: getUserType == userTypeEnum.Branch ? true : false,
    },
  ];

  return (
    <React.Fragment>
      {props.showAddParkingLotPopup && (
        <AddParkingLot
          id={selectedId}
          modalClosed={props.addParkingLotToggleHandler}
        />
      )}

      {props.showAddThresholdPopup && (
        <AddEditThreshold
          id={selectedId}
          modalClosed={props.addThresholdToggleHandler}
        />
      )}

      {props.showAddStatusImagesPopup && (
        <AddEditImageStatus
          id={selectedId}
          modalClosed={props.addStatusImagesToggleHandler}
        />
      )}

      {props.showAddLinkbaseInPopup && (
        <AddUpdateLinkbaseIn
          id={selectedId}
          parkingLotId={selectedParkingLotId}
          modalClosed={props.addLinkbaseInToggleHandler}
        />
      )}

      {props.showAddLinkbaseInThresholdPopup && (
        <AddEditLinkbaseInThreshold
          id={selectedId}
          modalClosed={props.addLinkbaseInThresholdToggleHandler}
        />
      )}

      {props.showConfirmPopup && (
        <ConfirmPopup
          title={
            currentStatus == parkingLotStatus.Available ||
            currentStatus == parkingLotStatus.Crowded ||
            currentStatus == parkingLotStatus.Full
              ? "close_parking_lot"
              : "open_parking_lot"
          }
          modalSubmit={parkingLotChangeStatusHandler}
          modalClosed={props.confirmPopupToggleHandler}
          message={
            currentStatus == parkingLotStatus.Available ||
            currentStatus == parkingLotStatus.Crowded ||
            currentStatus == parkingLotStatus.Full
              ? "close_parking_lot_message"
              : "open_parking_lot_message"
          }
          isLoading={updateParkingLotStatusState.inProgress}
        />
      )}

      {props.showDeletePopup && (
        <DeletePopup
          title="delete_parking_lot"
          modalSubmit={deleteHandler}
          modalClosed={props.deletePopupToggleHandler}
          message="message_delete_parking_lot_confirm"
          isLoading={deleteParkingLotState.inProgress}
        />
      )}
      {
        <FormLoader
          loading={
            inProgress ||
            addUpdateParkingLotState.inProgress ||
            deleteParkingLotState.inProgress
          }
        />
      }
      <div className="icon-nav navbar-fixed-top device-nav-bar userTopNav">
        <div className="row align-items-center">
          <div className="col-md-6 col-12">
            <div className="page_title_wrap">
              <h1 className="page_title">
                <FormattedMessage id="ParkingLotManagement" />
              </h1>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <FormattedMessage id="Home" />
                </li>
                {getIsTenantAccessFromLocalStorage() == true && (
                  <li className="breadcrumb-item">
                    {getTenantAccess.tenantName}
                  </li>
                )}
                {/* <li className="breadcrumb-item active" aria-current="page">
                  <FormattedMessage id="ParkingLotManagement" />
                </li> */}
              </ol>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <ul className="add-list">
              {isOperationPermittedRoleBase(
                ModuleType.ParkingManagementButtons,
                "Add"
              ) && (
                <li title={formatMessage("add_parking_lot")} className="ms-1">
                  <a
                    className="btn btn-secondary"
                    data-toggle="modal"
                    aria-disabled={true}
                    onClick={(event: any) =>
                      openAddParkingLotHandler({ PlId: -1 }, event)
                    }
                    data-testid="modalbtn"
                    data-target="#addUser"
                  >
                    <svg
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-plus m-0"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                    </svg>
                    <FormattedMessage id="add_parking_lot" />
                  </a>
                </li>
              )}

              {/* <li className="filter-dropdown" title={formatMessage("filter")}>
                <div>
                  <button
                    className="btn btn-light dropdown-toggle"
                    type="button"
                    id="dropdownMenuClickableInsideFilter"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-expanded="false"
                  >
                    <svg
                      className="svg-icon"
                      width="20px"
                      height="20px"
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M859.02 234.524l0.808-0.756 0.749-0.813c27.047-29.356 33.876-70.34 17.823-106.957-15.942-36.366-50.416-58.957-89.968-58.957H163.604c-38.83 0-73.043 22.012-89.29 57.444-16.361 35.683-10.632 76.301 14.949 106.004l0.97 1.126 280.311 266.85 2.032 312.074c0.107 16.502 13.517 29.805 29.995 29.805l0.2-0.001c16.568-0.107 29.912-13.626 29.804-30.194l-2.198-337.564-296.478-282.241c-9.526-11.758-11.426-26.933-5.044-40.851 6.446-14.059 19.437-22.452 34.75-22.452h624.828c15.6 0 28.69 8.616 35.017 23.047 6.31 14.391 3.924 29.831-6.354 41.497l-304.13 284.832 1.302 458.63c0.047 16.54 13.469 29.916 29.998 29.915h0.087c16.568-0.047 29.962-13.517 29.915-30.085L573.04 502.36l285.98-267.836z"
                        fill="currentColor"
                      ></path>
                      <path
                        d="M657.265 595.287c0 16.498 13.499 29.997 29.997 29.997h243.897c16.498 0 29.997-13.498 29.997-29.997 0-16.498-13.499-29.997-29.997-29.997H687.262c-16.498 0-29.997 13.499-29.997 29.997z m273.894 138.882H687.262c-16.498 0-29.997 13.499-29.997 29.997s13.499 29.997 29.997 29.997h243.897c16.498 0 29.997-13.499 29.997-29.997 0-16.498-13.499-29.997-29.997-29.997z m0 168.878H687.262c-16.498 0-29.997 13.499-29.997 29.997s13.499 29.997 29.997 29.997h243.897c16.498 0 29.997-13.499 29.997-29.997 0-16.498-13.499-29.997-29.997-29.997z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </button>

                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuClickableInsideFilter"
                  >
                    <div className="px-3 pt-3">
                      <div className="form-group mb-1 row d-flex">
                        <Label
                          className="label-style"
                          required={false}
                          label="parking_lot_name"
                        />
                        <a className="dropdown-item">
                          <input
                            type="text"
                            value={parkingLotName}
                            className="form-control"
                            name="ParkingLotName"
                            placeholder={formatMessage("parking_lot_name")}
                            onChange={(e) => handleParkingLotName(e)}
                          />
                        </a>
                      </div>
                      <div className="form-group mb-1 row d-flex">
                        <Label
                          className="label-style"
                          required={false}
                          label="parking_lot_id"
                        />
                        <a className="dropdown-item">
                          <input
                            type="text"
                            value={parkingLotId}
                            className="form-control"
                            name="ParkingLotId"
                            placeholder={formatMessage("parking_lot_id")}
                            onChange={(e) => handleParkingLotId(e)}
                          />
                        </a>
                      </div>
                      <div className="form-group mb-1 row d-flex">
                        <Label
                          className="label-style"
                          required={false}
                          label="select_status"
                        />
                        <a className="dropdown-item">
                          <select
                            id="ParkingStatus"
                            name="ParkingStatus"
                            className="form-control"
                            value={parkingStatus}
                            onChange={handleParkingStatus}
                          >
                            {parkingLotStatusOptions.map((status) => (
                              <option key={status.value} value={status.id}>
                                <FormattedMessage id={status.value} />
                              </option>
                            ))}
                          </select>
                        </a>
                      </div>
                    </div>
                    <div className="dropdown-divider"></div>
                    <div className="text-end me-3">
                      <button
                        data-testid="AddConfigurationButton"
                        className="btn btn-secondary"
                        type="button"
                        onClick={() => {
                          parkingLotName?.trim() !== "" ||
                          parkingLotId?.trim() !== "" ||
                          parkingStatus !== -1
                            ? setFilterState({
                                ParkingLotName: parkingLotName,
                                ParkingLotId: parkingLotId,
                                Status: parkingStatus,
                                IsFilterActive: true,
                              })
                            : toast.error(
                                formatMessage("select_at_least_one_parameter")
                              );
                        }}
                      >
                        <FormattedMessage id="apply" />
                      </button>
                    </div>
                  </ul>
                </div>
              </li> */}

              {getParkingLotFilter.IsFilterActive == true && (
                <li title={formatMessage("reset")}>
                  <a
                    data-testid="AddConfigurationButton"
                    className="btn btn-secondary"
                    type="button"
                    onClick={() => {
                      setIsRedirected(false);
                      setFilterState({
                        ParkingLotName: "",
                        ParkingLotId: "",
                        BranchName: "",
                        Status: -1,
                        IsFilterActive: false,
                      });
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      id="Layer_1"
                      x="0px"
                      y="0px"
                      width="20px"
                      height="20px"
                      className="me-2"
                      viewBox="0 0 122.88 110.668"
                      enable-background="new 0 0 122.88 110.668"
                    >
                      <g>
                        <path
                          fill="currentColor"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M91.124,15.645c12.928,0,23.406,10.479,23.406,23.406 c0,12.927-10.479,23.406-23.406,23.406c-12.927,0-23.406-10.479-23.406-23.406C67.718,26.125,78.197,15.645,91.124,15.645 L91.124,15.645z M2.756,0h117.322c1.548,0,2.802,1.254,2.802,2.802c0,0.848-0.368,1.622-0.996,2.139l-10.667,13.556 c-1.405-1.375-2.95-2.607-4.614-3.672l6.628-9.22H9.43l37.975,46.171c0.59,0.516,0.958,1.254,0.958,2.102v49.148l21.056-9.623 V57.896c1.651,1.9,3.548,3.582,5.642,4.996v32.133c0,1.105-0.627,2.064-1.586,2.506l-26.476,12.758 c-1.327,0.773-3.023,0.332-3.798-1.033c-0.258-0.441-0.368-0.92-0.368-1.4V55.02L0.803,4.756c-1.07-1.106-1.07-2.839,0-3.945 C1.355,0.258,2.056,0,2.756,0L2.756,0z M96.93,28.282c1.328-1.349,3.489-1.355,4.825-0.013c1.335,1.342,1.341,3.524,0.013,4.872 l-5.829,5.914l5.836,5.919c1.317,1.338,1.299,3.506-0.04,4.843c-1.34,1.336-3.493,1.333-4.81-0.006l-5.797-5.878l-5.807,5.889 c-1.329,1.349-3.489,1.355-4.826,0.013c-1.335-1.342-1.341-3.523-0.013-4.872l5.83-5.913l-5.836-5.919 c-1.317-1.338-1.3-3.507,0.04-4.843c1.339-1.336,3.492-1.333,4.81,0.006l5.796,5.878L96.93,28.282L96.93,28.282z"
                        />
                      </g>
                    </svg>
                    <FormattedMessage id="reset_filter" />
                  </a>
                </li>
              )}

              <li className="filter-dropdown" title={formatMessage("filter")}>
                <Dropdown>
                  <Dropdown.Toggle
                    className="btn btn-light"
                    aria-expanded="false"
                  >
                    <svg
                      className="svg-icon"
                      width="20px"
                      height="20px"
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M859.02 234.524l0.808-0.756 0.749-0.813c27.047-29.356 33.876-70.34 17.823-106.957-15.942-36.366-50.416-58.957-89.968-58.957H163.604c-38.83 0-73.043 22.012-89.29 57.444-16.361 35.683-10.632 76.301 14.949 106.004l0.97 1.126 280.311 266.85 2.032 312.074c0.107 16.502 13.517 29.805 29.995 29.805l0.2-0.001c16.568-0.107 29.912-13.626 29.804-30.194l-2.198-337.564-296.478-282.241c-9.526-11.758-11.426-26.933-5.044-40.851 6.446-14.059 19.437-22.452 34.75-22.452h624.828c15.6 0 28.69 8.616 35.017 23.047 6.31 14.391 3.924 29.831-6.354 41.497l-304.13 284.832 1.302 458.63c0.047 16.54 13.469 29.916 29.998 29.915h0.087c16.568-0.047 29.962-13.517 29.915-30.085L573.04 502.36l285.98-267.836z"
                        fill="currentColor"
                      ></path>
                      <path
                        d="M657.265 595.287c0 16.498 13.499 29.997 29.997 29.997h243.897c16.498 0 29.997-13.498 29.997-29.997 0-16.498-13.499-29.997-29.997-29.997H687.262c-16.498 0-29.997 13.499-29.997 29.997z m273.894 138.882H687.262c-16.498 0-29.997 13.499-29.997 29.997s13.499 29.997 29.997 29.997h243.897c16.498 0 29.997-13.499 29.997-29.997 0-16.498-13.499-29.997-29.997-29.997z m0 168.878H687.262c-16.498 0-29.997 13.499-29.997 29.997s13.499 29.997 29.997 29.997h243.897c16.498 0 29.997-13.499 29.997-29.997 0-16.498-13.499-29.997-29.997-29.997z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.ItemText>
                      <div className="px-3 pt-3">
                        <div className="form-group mb-1 row d-flex">
                          <Label
                            className="label-style"
                            required={false}
                            label="parking_lot_name"
                          />
                          <a className="dropdown-item">
                            <input
                              type="text"
                              value={parkingLotName}
                              className="form-control"
                              name="ParkingLotName"
                              placeholder={formatMessage("parking_lot_name")}
                              onChange={(e) => handleParkingLotName(e)}
                            />
                          </a>
                        </div>
                        <div className="form-group mb-1 row d-flex">
                          <Label
                            className="label-style"
                            required={false}
                            label="branch_name"
                          />
                          <a className="dropdown-item">
                            <input
                              type="text"
                              value={branchName}
                              className="form-control"
                              name="BranchName"
                              placeholder={formatMessage("branch_name")}
                              onChange={(e) => handleBranchName(e)}
                            />
                          </a>
                        </div>
                        <div className="form-group mb-1 row d-flex">
                          <Label
                            className="label-style"
                            required={false}
                            label="parking_lot_id"
                          />
                          <a className="dropdown-item">
                            <input
                              type="text"
                              value={parkingLotId}
                              className="form-control"
                              name="ParkingLotId"
                              placeholder={formatMessage("parking_lot_id")}
                              onChange={(e) => handleParkingLotId(e)}
                            />
                          </a>
                        </div>
                        <div className="form-group mb-1 row d-flex">
                          <Label
                            className="label-style"
                            required={false}
                            label="select_status"
                          />
                          <a className="dropdown-item">
                            <select
                              id="ParkingStatus"
                              name="ParkingStatus"
                              className="form-control"
                              value={parkingStatus}
                              onChange={handleParkingStatus}
                            >
                              {parkingLotStatusOptions.map((status) => (
                                <option key={status.value} value={status.id}>
                                  <FormattedMessage id={status.value} />
                                </option>
                              ))}
                            </select>
                          </a>
                        </div>
                      </div>
                    </Dropdown.ItemText>
                    <Dropdown.Divider />
                    <div className="text-end me-3">
                      <Dropdown.Item
                        as="button"
                        className="btn btn-primary cancel-btn-filter d-inline-flex me-2"
                        onClick={props.onModalClosed}
                        disabled={props.addUpdateFolderLoading}
                      >
                        <FormattedMessage id="button_cancel" />
                      </Dropdown.Item>

                      <button
                        data-testid="AddConfigurationButton"
                        className="btn btn-secondary"
                        type="button"
                        onClick={() => {
                          parkingLotName?.trim() !== "" ||
                          branchName?.trim() !== "" ||
                          parkingLotId?.trim() !== "" ||
                          parkingStatus !== -1
                            ? setFilterState({
                                ParkingLotName: parkingLotName,
                                ParkingLotId: parkingLotId,
                                BranchName: branchName,
                                Status: parkingStatus,
                                IsFilterActive: true,
                              })
                            : toast.error(
                                formatMessage("select_at_least_one_parameter")
                              );
                        }}
                      >
                        <FormattedMessage id="apply" />
                      </button>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </li>

              <li title={formatMessage("refresh")}>
                <a
                  data-toggle="modal"
                  className="btn btn-light"
                  onClick={() => refreshClickHandler()}
                >
                  <svg
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-arrow-repeat m-0"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                    <path
                      fillRule="evenodd"
                      d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"
                    />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {allParkingLots.length > 0 ? (
        <div className={`${openPanel ? "panelOpen" : ""}`}>
          <div className="card changingPanel">
            <div className="tableDesign row-eq-height tableDevices ">
              <div className="table-responsive" data-testid="table">
                {allParkingLots && (
                  <Grid
                    data={allParkingLots}
                    columns={columns}
                    keyField="PlId"
                    loading={false}
                    rowClasses={rowClassFormat}
                    rowEvents={{ onClick: rowEvents }}
                  />
                )}
              </div>
            </div>
          </div>

          {allParkingLots.length > 0 ? (
            <div className="row no-gutters p-2 tableFooter">
              <div className="col-sm-6 pt-1">
                <FormattedMessage id="show" />
                <select
                  data-testid="pageSelect"
                  className="pageLimit"
                  onChange={onPageLimitChanged}
                  value={pageLimitInfo.pageLimit}
                >
                  {pageLimitOptions.map((op: any) => (
                    <option key={op} value={op}>
                      {op}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-sm-6 d-flex justify-content-sm-end">
                <Pagination
                  isRefreshPage={pageInfo.isRefreshPage}
                  totalPages={pageInfo.totalPages}
                  pageLimit={pageLimitInfo.pageLimit}
                  onPageChanged={onPageChanged}
                  disablePagination={disablePagination}
                  setIsRefreshPage={setIsRefreshPage}
                />
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : getParkingLotFilter.IsFilterActive == true ? (
        <div className="message text-center">
          <img src={noMessageIcon} alt="" className="no_message" />
          <p className="noData text-muted text-center h4 mb-0">
            <FormattedMessage id="no_parking_lot_found" />
          </p>
          {/* <p className="mb-3">{formatMessage("no_parking_lot_message")}</p> */}
          {/* {isOperationPermittedRoleBase(ModuleType.ParkingManagementList, 'Add') && (
            <LoaderButton
              text="add_parking_lot"
              onClick={(event: any) =>
                openAddParkingLotHandler({ PlId: -1 }, event)
              }
            />
          )} */}
        </div>
      ) : (
        <div className="message text-center">
          <img src={noMessageIcon} alt="" className="no_message" />
          <p className="noData text-muted text-center h4 mb-0">
            <FormattedMessage id="no_parking_lot" />
          </p>
          <p className="mb-3">{formatMessage("no_parking_lot_message")}</p>
          {isOperationPermittedRoleBase(
            ModuleType.ParkingManagementList,
            "Add"
          ) && (
            <LoaderButton
              text="add_parking_lot"
              onClick={(event: any) =>
                openAddParkingLotHandler({ PlId: -1 }, event)
              }
            />
          )}
        </div>
      )}

      <SlidingPanel
        type={"right"}
        isOpen={openPanel}
        noBackdrop={false}
        size={100}
      >
        <div className="bg_slide panel">
          <div className="row bb p-2 pl-4 g-0 align-items-center ">
            <div className="col-lg-10">
              <h2 className="fw-bold mb-0 mt-1 ms-2 py-2">
                {selectedRow.ParkingLotId}
              </h2>
            </div>
            <div className="col-lg-2">
              <span title={formatMessage("close")}>
                <svg
                  onClick={closePanelhandler}
                  role="button"
                  xlinkTitle="close"
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="currentColor"
                  className="bi bi-x ms-auto d-block"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </span>
            </div>
          </div>
          <div className="group_detailsWrapper pb-3">
            <ParkingLotDetails selectedData={selectedRow} />
          </div>
        </div>
      </SlidingPanel>
    </React.Fragment>
  );
};

export default AddLinkbaseInThresholdPopupHOC(
  AddLinkbaseInPopupHOC(
    AddStatusImagesPopupHOC(
      AddThresholdPopupHOC(
        ConfirmPopupHOC(
          DeletePopupHOC(AddParkingLotPopupHOC(observer(ParkingLot)))
        )
      )
    )
  )
);
