import { Form, Formik } from "formik";
import { FormattedMessage } from "react-intl";
import { parkingLotStatus } from "../../../constants/enums/parking-lot-status-enum";
import FormLoader from "../../../shared-components/FormLoader/FormLoader";
import { RouteAreaType } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import "./route-guidance-status-image-form.css";
import { formatMessage } from "../../../translations/format-message";

interface IProps {
  id: number;
  onModalClosed: () => void;
  isDataLoading: boolean;
  isLoading: boolean;
  initialValues: any;
}
const RouteGuidanceStatusImageForm = (props: IProps) => {
  const handleSubmit = (values: any) => {};

  return (
    <Formik
      initialValues={props.initialValues}
      onSubmit={handleSubmit}
      validationSchema={RouteAreaType}
      validateOnBlur={false}
      enableReinitialize={true}
    >
      {({ values, setFieldValue }) => {
        return (
          <Form id="formBody">
            {(props.isDataLoading || props.isLoading) && (
              <FormLoader loading={props.isDataLoading || props.isLoading} />
            )}
            {/* {JSON.stringify(values.RouteAnimationDetailsVMs)} */}
            <div className="row">
              <div className="tableDesign border">
                <table className="table table-bordered border route-table">
                  <thead>
                    <tr>
                      {props.initialValues?.RouteAreaDetailsVMs?.map(
                        (area: any, index: number) => {
                          return <th className="wrap_10">{area?.Name}</th>;
                        }
                      )}
                      <th className="route_column_1">
                        <FormattedMessage id="folder_name" />
                      </th>
                      <th className="route_column_2">
                        <FormattedMessage id="animation" />
                      </th>
                      <th className="route_column_3">
                        <FormattedMessage id="media_width" />
                      </th>
                      <th className="route_column_4">
                        <FormattedMessage id="media_height" />
                      </th>
                      <th className="route_column_5">
                        <FormattedMessage id="comment" />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.initialValues.RouteAnimationDetailsVMs?.map(
                      (routeAnimationDetailsVM: any, index: number) => (
                        <tr key={index} className="">
                          {props.initialValues?.RouteAreaDetailsVMs?.map(
                            (area: any, index: number) => {
                              return (
                                <td>
                                  {
                                    <FormattedMessage id={parkingLotStatus[
                                      routeAnimationDetailsVM[
                                        `Area${index + 1}Status`
                                      ]
                                    ]} />
                                  }
                                </td>
                              );
                            }
                          )}
                          <td>
                            <p>{routeAnimationDetailsVM.FolderName}</p>
                          </td>
                          <td>
                            <p>{routeAnimationDetailsVM.ImageName}</p>
                          </td>
                          <td>
                            <p>{routeAnimationDetailsVM.Width}</p>
                          </td>
                          <td>
                            <p>{routeAnimationDetailsVM.Height}</p>
                          </td>
                          <td>
                            <p title={routeAnimationDetailsVM.Comment} className="wrap_20">{routeAnimationDetailsVM.Comment}</p>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {/* <div className="pb-3 mt-4 d-flex justify-content-center">
              <Button
                data-testid="cancel"
                variant="primary"
                onClick={props.onModalClosed}
                disabled={props.isLoading}
                className="mr-3"
              >
                <FormattedMessage id="Cancel" />
              </Button>
            </div> */}
          </Form>
        );
      }}
    </Formik>
  );
};

export default RouteGuidanceStatusImageForm;
