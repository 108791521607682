import { Field, FieldArray, Form, Formik } from "formik";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { parkingLotStatus } from "../../../constants/enums/parking-lot-status-enum";
import { areaTypeOptions } from "../../../constants/options.constant";
import { IOption } from "../../../models/ICommon";
import FormLoader from "../../../shared-components/FormLoader/FormLoader";
import FormikFormCheckBox from "../../../shared-components/FormikFormHandlers/FormikFormCheckBox";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import FormikFormOnChangeSelectRoute from "../../../shared-components/FormikFormHandlers/FormikFormOnChangeSelectRoute";
import FormikFormSelect from "../../../shared-components/FormikFormHandlers/FormikFormSelect";
import { RouteAreaType } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import LoaderButton from "../../../shared-components/button/loader-button";
import Label from "../../../shared-components/label/label";
import "./add-update-route-guidance-form.css";
import { formatMessage } from "../../../translations/format-message";

interface IProps {
  id: number;
  folderOptions: IOption[];
  imageOptions: any;
  submitHandler: (data: any) => void;
  routeAnimationDetails: any;
  getImagesByFolderData: (data: any, key: any) => void;
  updateImageFolder: (key:number) => void;
  onModalClosed: () => void;
  previousButton: (data:any) => void;
  isDataLoading: boolean;
  isLoading: boolean;
  initialValues: any;
}
const RouteAnimationDetailsForm = (props: IProps) => {
  const handleSubmit = (values: any) => {
    props.submitHandler(values);
  };

  return (
    <Formik
      initialValues={props.initialValues}
      onSubmit={handleSubmit}
      validationSchema={RouteAreaType}
      validateOnBlur={false}
      enableReinitialize={true}
    >
      {({ values, setFieldValue }) => {
        return (
          <Form id="formBody">
            {(props.isDataLoading || props.isLoading) && (
              <FormLoader loading={props.isDataLoading || props.isLoading} />
            )}
            {/* {JSON.stringify(values.RouteAnimationDetailsVMs)} */}
            <div className="pt-3">
              <div className="progress px-1 step-progress">
                <div
                  className={`progress-bar ${
                    props.id === -1 ? "w-100" : "w-100"
                  }`}
                  role="progressbar"
                ></div>
              </div>
              <div className="step-container d-flex justify-content-between">
                <div className="step-circle completed">
                  1{" "}
                  <span className="step-detail">
                    <FormattedMessage id="route_details" />
                  </span>
                </div>
                <div className="step-circle completed">
                  2{" "}
                  <span className="step-detail">
                    <FormattedMessage id="area_details" />
                  </span>
                </div>
                <div className="step-circle active">
                  3{" "}
                  <span className="step-detail">
                    <FormattedMessage id="animation_details" />
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 col-12">
                <div className="form-group formSet">
                  <Label
                    className="label-style  col-form-label"
                    required={true}
                    label="route_id"
                  />
                  <Field
                    data-testid="RouteId"
                    className="form-control"
                    name="RouteId"
                    placeholder="route_id"
                    invalidcharacter={[" "]}
                    disabled={true}
                    type="text"
                    as={FormikFormInput}
                  />
                </div>
              </div>

              <div className="col-md-4 col-12">
                <div className="form-group formSet">
                  <Label
                    className="label-style col-form-label"
                    required={true}
                    label="route_name"
                  />
                  <Field
                    data-testid="RouteName"
                    className="form-control"
                    name="RouteName"
                    placeholder="route_name"
                    disabled={true}
                    type="text"
                    as={FormikFormInput}
                  />
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="form-group formSet">
                  <Label
                    className="label-style  col-form-label"
                    required={true}
                    label="area_type"
                  />
                  <Field
                    data-testid="AreaType"
                    className="form-control"
                    name="AreaType"
                    placeholder="area_type"
                    disabled={true}
                    options={areaTypeOptions}
                    as={FormikFormSelect}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="tableDesign border">
                <table className="table table-bordered border route-table">
                  <thead>
                    <tr>
                      {/* <th>S.No</th> */}
                      {values?.RouteAreaDetailsVMs?.map(
                        (area: any, index: number) => {
                          return <th className="wrap_10">{area?.Name}</th>;
                        }
                      )}
                      <th className="route_col_1">
                        <FormattedMessage id="select_folder" />
                      </th>
                      <th className="route_col_2">
                        <FormattedMessage id="animation" />
                      </th>
                      <th className="route_col_3">
                        <FormattedMessage id="media_width" />
                      </th>
                      <th className="route_col_4">
                        <FormattedMessage id="media_height" />
                      </th>
                      <th className="route_col_5">
                        <FormattedMessage id="comment" />
                      </th>
                      {props.id > 0 && (
                        <th className="route_col_6">
                          {/* <FormattedMessage id="actions" /> */}
                        </th>
                      )}
                    </tr>
                  </thead>
                  <FieldArray name="RouteAnimationDetailsVMs">
                    {({ insert, remove, push }) => (
                      <tbody>
                        {values.RouteAnimationDetailsVMs?.map(
                          (routeAnimationDetailsVM: any, index: number) => (
                            <tr key={index} className="">
                              {/* <td>{index + 1}</td> */}
                              <Field name={`RouteAnimationDetailsVMs.${index}`}>
                                {({ field }: any) => (
                                  <React.Fragment>
                                    {values?.RouteAreaDetailsVMs?.map(
                                      (area: any, index: number) => {
                                        return (
                                          <td>
                                            {
                                              <FormattedMessage id={parkingLotStatus[
                                                routeAnimationDetailsVM[
                                                  `Area${index + 1}Status`
                                                ]
                                              ]} />
                                              
                                            }
                                          </td>
                                        );
                                      }
                                    )}
                                    <td>
                                      {values?.RouteAnimationDetailsVMs[index]
                                        .IsEdit == true || props.id < 0 ? (
                                        <div className="form-group mb-2 col-12">
                                          <Field
                                            data-testid="select_folder"
                                            className="form-control"
                                            name={`RouteAnimationDetailsVMs.${index}.FolderId`}
                                            placeholder="select_folder"
                                            options={props.folderOptions}
                                            folderKey={index}
                                            setLocalState={(value: any) => {
                                              setFieldValue(
                                                `RouteAnimationDetailsVMs.${index}.ImageId`,
                                                -1
                                              );
                                              setFieldValue(
                                                `RouteAnimationDetailsVMs.${index}.FolderName`,
                                                ""
                                              );
                                              setFieldValue(
                                                `RouteAnimationDetailsVMs.${index}.FolderName`,
                                                (
                                                  props.folderOptions || []
                                                ).find(
                                                  (item: IOption) =>
                                                    Number(item.id) ==
                                                    Number(value)
                                                )?.value || ""
                                              );
                                              props.getImagesByFolderData(
                                                value,
                                                index + 1
                                              );
                                            }}
                                            as={FormikFormOnChangeSelectRoute}
                                          />
                                        </div>
                                      ) : (
                                        <p>
                                          {
                                            values?.RouteAnimationDetailsVMs[
                                              index
                                            ].FolderName
                                          }
                                        </p>
                                      )}
                                    </td>
                                    <td>
                                      {values?.RouteAnimationDetailsVMs[index]
                                        .IsEdit == true || props.id < 0 ? (
                                        <div className="form-group mb-2 col-12">
                                          <Field
                                            data-testid="select_media"
                                            className="form-control"
                                            name={`RouteAnimationDetailsVMs.${index}.ImageId`}
                                            placeholder="select_media"
                                            folderKey={index}
                                            options={
                                              props.imageOptions[
                                                `folder${index + 1}`
                                              ]
                                            }
                                            setLocalState={(e: any) => {
                                              setFieldValue(
                                                `RouteAnimationDetailsVMs.${index}.ImageName`,
                                                props.imageOptions[
                                                  `folder${index + 1}`
                                                ]?.find(
                                                  (item: IOption) =>
                                                    Number(item.id) == Number(e)
                                                ).value
                                              );
                                            }}
                                            as={FormikFormOnChangeSelectRoute}
                                          />
                                        </div>
                                      ) : (
                                        <p>
                                          {
                                            values?.RouteAnimationDetailsVMs[
                                              index
                                            ].ImageName
                                          }
                                        </p>
                                      )}
                                    </td>
                                    <td>
                                      {values?.RouteAnimationDetailsVMs[index]
                                        .IsEdit == true || props.id < 0 ? (
                                        <div className="form-group mb-2 col-12">
                                          <Field
                                            data-testid="width"
                                            className="form-control"
                                            name={`RouteAnimationDetailsVMs.${index}.Width`}
                                            placeholder="media_width"
                                            type="number"
                                            // folderKey={index}
                                            // options={
                                            //   props.imageOptions[
                                            //     `folder${index + 1}`
                                            //   ]
                                            // }
                                            // setLocalState={(e: any) => {
                                            //   setFieldValue(
                                            //     `RouteAnimationDetailsVMs.${index}.ImageName`,
                                            //     props.imageOptions[
                                            //       `folder${index + 1}`
                                            //     ]?.find(
                                            //       (item: IOption) =>
                                            //         Number(item.id) == Number(e)
                                            //     ).value
                                            //   );
                                            // }}
                                            // as={FormikFormOnChangeSelectRoute}
                                            as={FormikFormInput}
                                          />
                                        </div>
                                      ) : (
                                        <p>
                                          {
                                            values?.RouteAnimationDetailsVMs[
                                              index
                                            ].Width
                                          }
                                        </p>
                                      )}
                                    </td>
                                    <td>
                                      {values?.RouteAnimationDetailsVMs[index]
                                        .IsEdit == true || props.id < 0 ? (
                                        <div className="form-group mb-2 col-12">
                                          <Field
                                            data-testid="height"
                                            className="form-control"
                                            name={`RouteAnimationDetailsVMs.${index}.Height`}
                                            placeholder="media_height"
                                            type="number"
                                            // folderKey={index}
                                            // options={
                                            //   props.imageOptions[
                                            //     `folder${index + 1}`
                                            //   ]
                                            // }
                                            // setLocalState={(e: any) => {
                                            //   setFieldValue(
                                            //     `RouteAnimationDetailsVMs.${index}.ImageName`,
                                            //     props.imageOptions[
                                            //       `folder${index + 1}`
                                            //     ]?.find(
                                            //       (item: IOption) =>
                                            //         Number(item.id) == Number(e)
                                            //     ).value
                                            //   );
                                            // }}
                                            // as={FormikFormOnChangeSelectRoute}
                                            as={FormikFormInput}
                                          />
                                        </div>
                                      ) : (
                                        <p>
                                          {
                                            values?.RouteAnimationDetailsVMs[
                                              index
                                            ].Height
                                          }
                                        </p>
                                      )}
                                    </td>
                                    <td>
                                      {values?.RouteAnimationDetailsVMs[index]
                                        .IsEdit == true || props.id < 0 ? (
                                        <div className="form-group mb-2 col-12">
                                          <Field
                                            data-testid="Comment"
                                            className="form-control"
                                            name={`RouteAnimationDetailsVMs.${index}.Comment`}
                                            placeholder="comment"
                                            type="text"
                                            as={FormikFormInput}
                                          />
                                        </div>
                                      ) : (
                                        <p className="wrap_20" title={values?.RouteAnimationDetailsVMs[
                                          index
                                        ].Comment}>
                                          {
                                            values?.RouteAnimationDetailsVMs[
                                              index
                                            ].Comment
                                          }
                                        </p>
                                      )}
                                    </td>
                                    {props.id > 0 && (
                                      <td>
                                        <div className="form-group pt-2 col-12">
                                          <label className="with-label-checkbox edit_checkbox" title="edit">
                                            <Field
                                              data-testid="testRememberMe"
                                              className="form-control"
                                              name={`RouteAnimationDetailsVMs.${index}.IsEdit`}
                                              type="checkbox"
                                              id="inlineFormCheck"
                                              getChangeValue={(e: any) => {
                                                if (e == true) {
                                                  setFieldValue(
                                                    `RouteAnimationDetailsVMs.${index}.FolderId`,
                                                    -1
                                                  );
                                                  setFieldValue(
                                                    `RouteAnimationDetailsVMs.${index}.ImageId`,
                                                    -1
                                                  );
                                                  setFieldValue(
                                                    `RouteAnimationDetailsVMs.${index}.FolderName`,
                                                    ""
                                                  );
                                                  setFieldValue(
                                                    `RouteAnimationDetailsVMs.${index}.ImageName`,
                                                    ""
                                                  );
                                                  setFieldValue(
                                                    `RouteAnimationDetailsVMs.${index}.Width`,
                                                    500
                                                  );
                                                  setFieldValue(
                                                    `RouteAnimationDetailsVMs.${index}.Height`,
                                                    500
                                                  );
                                                } else {
                                                  setFieldValue(
                                                    `RouteAnimationDetailsVMs.${index}.FolderId`,
                                                    props
                                                      .routeAnimationDetails?.[
                                                      index
                                                    ].FolderId
                                                  );
                                                  setFieldValue(
                                                    `RouteAnimationDetailsVMs.${index}.ImageId`,
                                                    props
                                                      .routeAnimationDetails?.[
                                                      index
                                                    ].ImageId
                                                  );
                                                  setFieldValue(
                                                    `RouteAnimationDetailsVMs.${index}.Comment`,
                                                    props
                                                      .routeAnimationDetails?.[
                                                      index
                                                    ].Comment
                                                  );
                                                  setFieldValue(
                                                    `RouteAnimationDetailsVMs.${index}.FolderName`,
                                                    props
                                                      .routeAnimationDetails?.[
                                                      index
                                                    ].FolderName
                                                  );
                                                  setFieldValue(
                                                    `RouteAnimationDetailsVMs.${index}.ImageName`,
                                                    props
                                                      .routeAnimationDetails?.[
                                                      index
                                                    ].ImageName
                                                  );
                                                  setFieldValue(
                                                    `RouteAnimationDetailsVMs.${index}.Width`,
                                                    props
                                                      .routeAnimationDetails?.[
                                                      index
                                                    ].Width
                                                  );
                                                  setFieldValue(
                                                    `RouteAnimationDetailsVMs.${index}.Height`,
                                                    props
                                                      .routeAnimationDetails?.[
                                                      index
                                                    ].Height
                                                  );
                                                  props.updateImageFolder(index+1);
                                                }
                                              }}
                                              as={FormikFormCheckBox}
                                            />
                                            <span className="checkmark" title={values.RouteAnimationDetailsVMs[index].IsEdit == true ?  formatMessage("button_cancel"):  formatMessage("button_update")}></span>
                                            {/* <p className="ms-4">Update</p> */}
                                          </label>
                                        </div>
                                      </td>
                                    )}
                                  </React.Fragment>
                                )}
                              </Field>
                            </tr>
                          )
                        )}
                      </tbody>
                    )}
                  </FieldArray>
                </table>
              </div>
            </div>
            <div className="pb-3 mt-4 d-flex justify-content-center">
              <Button
                data-testid="back"
                variant="light-secondary"
                onClick={() => {
                  props.previousButton(values);
                }}
                disabled={props.isLoading}
                className="mr-3"
              >
                <FormattedMessage id="back" />
              </Button>
              <LoaderButton
                type="submit"
                id="SaveParkingLot"
                className="ms-2"
                isLoading={props.isLoading}
                text={props.id == -1 ? "submit" : "update_route_guidance"}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default RouteAnimationDetailsForm;
