/*    
<summary>
   This component is Used for Activating Huckster Account.
   Developer: Mohammad Saquib Khan, Created Date:14-May-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns JSX</returns>
*/

import "./reset-password-form.css";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import "react-sliding-side-panel/lib/index.css";
import noMessageIcon from "../../assets/Images/svg/018-empty.svg";
import { formatMessage } from "../../../translations/format-message";
import FormLoader from "../../../shared-components/FormLoader/FormLoader";
import { useHistory, useLocation } from "react-router-dom";
import { useStore } from "../../../contexts/store-provider";
import accountStatusEnum from "../../../constants/enums/account-status-type-enum";
import { Field, Form, Formik } from "formik";
import {
  ResetPasswordValidateSchema,
  UserChangePasswordValidateSchema,
} from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import { Button, Modal } from "react-bootstrap";
import Label from "../../../shared-components/label/label";
import { ShowHidePasswordButton } from "../../../shared-components/Grid/GridFormatter";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import LoaderButton from "../../../shared-components/button/loader-button";
import loginBackground from "../../../assets/Images/backgroundLoginSvg.svg";
import hucksterLogo from "../../../assets/Images/logo.svg";
import monozlogo from "../../../assets/Images/monoZ_Logo.png";
import RoutesConstants from "../../../constants/routes.constant";
import moment from "moment";

const ResetPasswordForm = (props: any) => {
  const history = useHistory();
  const [showHidePassword, setShowHidePassword] = useState(false);
  const [showHideConfirmPassword, setShowHideConfirmPassword] = useState(false);
  const { userStore } = useStore();
  const {
    VerifyUserByUserIdService,
    userState,
    userDetail,
    resetUserDetail,
    changePasswordState,
    resetChangePassword,
  } = userStore;
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const userId = params.get("uid") ? decodeURIComponent(escape(atob(params.get("uid")!))) : null;
  const email = params.get("em") ? decodeURIComponent(escape(atob(params.get("em")!))) : null;

  const goBackToLogin = () => {
    resetUserDetail();
    resetChangePassword();
    history.push(RoutesConstants.Login);
  };

  useEffect(() => {
    resetUserDetail();
    if (userId && email) {
      VerifyUserByUserIdService(Number(userId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      {<FormLoader loading={userState.inProgress} />}
      <Formik
        initialValues={{ NewPassword: "", ConfirmPassword: "" }}
        enableReinitialize
        validationSchema={ResetPasswordValidateSchema}
        validateOnBlur={false}
        onSubmit={async (values) => {
          props.submitHandler({ ...values, UserId: userDetail.Id });
        }}
      >
        <Form className="forget">
          <div className="form-login">
            <figure className="logoLogin">
              <img src={hucksterLogo} alt="logo" className="img-fluid"></img>
            </figure>
            <div className="svg-bg">
              <img
                src={loginBackground}
                alt="loginBackground"
                className="img-fluid login-wave"
              />
            </div>

            <div className="resetPasswordBox">
              {(userDetail.IsActive == "Active") &&
              (!moment().isAfter(moment(userDetail.LinkExpirationTime))) &&
              userDetail.Id > 0 &&
              userDetail.Email == email ? (
                <React.Fragment>
                  <div className="form-group">
                    <Label
                      required={true}
                      label="user_label_password"
                      htmlFor="email"
                    />

                    <Field
                      data-testid="testPassword"
                      className="form-control"
                      name="NewPassword"
                      type={showHidePassword ? "text" : "password"}
                      placeholder="user_label_password"
                      invalidcharacter={[" "]}
                      validcharacter={[
                        "~",
                        "`",
                        "!",
                        "@",
                        "#",
                        "$",
                        "%",
                        "^",
                        "&",
                        "*",
                        "(",
                        ")",
                        "_",
                        "-",
                        "+",
                        "=",
                        "{",
                        "[",
                        "}",
                        "]",
                        "|",
                        "\\",
                        ":",
                        ";",
                        "'",
                        '"',
                        "<",
                        ",",
                        ">",
                        ".",
                        "?",
                        "/",
                      ]}
                      as={FormikFormInput}
                    />
                    <ShowHidePasswordButton
                      showHidePassword={showHidePassword}
                      setShowHidePassword={setShowHidePassword}
                    />
                  </div>

                  <div className="form-group">
                    <Label
                      // className="label-style col-sm-4 col-form-label pe-0"
                      required={true}
                      label="label_confirm_password"
                    />
                    <Field
                      data-testid="testPassword"
                      className="form-control"
                      name="ConfirmPassword"
                      type={showHideConfirmPassword ? "text" : "password"}
                      placeholder="label_confirm_password"
                      invalidcharacter={[" "]}
                      validcharacter={[
                        "~",
                        "`",
                        "!",
                        "@",
                        "#",
                        "$",
                        "%",
                        "^",
                        "&",
                        "*",
                        "(",
                        ")",
                        "_",
                        "-",
                        "+",
                        "=",
                        "{",
                        "[",
                        "}",
                        "]",
                        "|",
                        "\\",
                        ":",
                        ";",
                        "'",
                        '"',
                        "<",
                        ",",
                        ">",
                        ".",
                        "?",
                        "/",
                      ]}
                      as={FormikFormInput}
                    />
                    <ShowHidePasswordButton
                      showHidePassword={showHideConfirmPassword}
                      setShowHidePassword={setShowHideConfirmPassword}
                    />
                  </div>

                  <div className="d-flex justify-content-center">
                    <LoaderButton
                      type="submit"
                      id="SaveTenant"
                      isLoading={props.isLoading}
                      text="update_password"
                    />
                    <Button
                      className="ms-2"
                      variant="secondary"
                      disabled={props.isLoading}
                      onClick={() => {
                        history.push(RoutesConstants.Login);
                      }}
                    >
                      <FormattedMessage id="button_cancel" />
                    </Button>
                  </div>
                </React.Fragment>
              ) : (
                <div className="text-center px-3">
                  <h2 className="font-white mt-2">
                    {userDetail.IsActive !== "Active"
                      ? <FormattedMessage id="account_inactive" />
                      : moment().isAfter(
                          moment(userDetail.LinkExpirationTime)
                        ) == true
                      ? <FormattedMessage id="link_expired" />
                      : <FormattedMessage id="invalid_link" />}
                  </h2>
                  <a
                    onClick={() => {
                      goBackToLogin();
                    }}
                    className="back_login"
                  >
                    <FormattedMessage id="back_to_login" />
                  </a>
                </div>
              )}
              {changePasswordState.success == true && (
                <div className="text-center px-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="35"
                    height="35"
                    fill="#868990"
                    className="bi bi-check-circle-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                  </svg>
                  <h2 className="font-white mt-2">
                    <FormattedMessage id="password_updated" />
                  </h2>
                  <a
                    onClick={() => {
                      goBackToLogin();
                    }}
                    className="font-white"
                  >
                    <FormattedMessage id="back_to_login" />
                  </a>
                </div>
              )}
            </div>
          </div>
          <figure className="logoMonoz">
            <span className="powered text-white">Powered by</span>
            <img src={monozlogo} alt="logo" className="img-fluid"></img>
          </figure>
        </Form>
      </Formik>
    </React.Fragment>
  );
};

export default observer(ResetPasswordForm);
