/*    
<summary>
   This class component is all about Managing media functionality.
   Developer:Mohammad Saquib Khan, Created Date:25-April-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns class instance</returns>
*/

import { action, computed, makeObservable, observable } from "mobx";
import toast from "react-hot-toast";
import folderCategoryTypeEnum from "../../constants/enums/folder-category-type-enum";
import URLConstants from "../../constants/url-constants";
import {
  IFolderOption,
  IImageOption,
  IObservableInitialState,
} from "../../models/ICommon";
import IApiResponse, {
  IApiSuccessResponse,
} from "../../models/response/IApiResponse";
import {
  IMediaFolderListVM,
  IMediaFolderVM,
  IMediaImageVM,
  IMediaList,
  IMediaListVM,
  IMediaVideoVM,
} from "../../models/response/IMediaResponse";
import { ICommonState } from "../../models/state/ICommonState";
import { IMediaState } from "../../models/state/IMediaState";
import { formatMessage } from "../../translations/format-message";
import { initialState as AddEditFolderState } from "../initialState/add-edit-folder-state";
import { initialState as AddEditImageMediaState } from "../initialState/add-edit-image-media-state";
import { initialState as AddEditPricingImageMediaState } from "../initialState/add-edit-pricing-image-media-state";
import { initialState as AddEditVideoMediaState } from "../initialState/add-edit-video-media-state";
import { initialState as GetAllFolderState } from "../initialState/get-all-folder-state";
import { initialState as GetAllMediaState } from "../initialState/get-all-media-state";
import { initialState as GetFolderStyleByCategoryidInitialState } from "../initialState/get-folder-style-categoryId-state";
import * as baseService from "../service/base-service";

export class MediaStore implements IMediaState, ICommonState {
  inProgress: boolean = false;
  error = "";

  initialStateValue: IObservableInitialState = {
    success: false,
    error: "",
    inProgress: false,
  };

  mediaList: IMediaListVM = GetAllMediaState;

  allFolders: IMediaFolderVM[] = [];
  allImages: IMediaImageVM[] = [];
  allTenantImages: IMediaImageVM[] = [];

  mediaFolderList: IMediaFolderListVM = GetAllFolderState;
  getAllFolderState = { ...this.initialStateValue };
  getAllImagesState = { ...this.initialStateValue };
  mediaFolderListState = { ...this.initialStateValue };

  mediaImage: any | undefined = undefined;
  mediaVideo: any | undefined = undefined;
  mediaFolder: any | undefined = undefined;
  folderStyleCategory: any = undefined;

  mediaState = { ...this.initialStateValue };
  uploadMediaState = { ...this.initialStateValue };
  mediaFolderState = { ...this.initialStateValue };
  deleteMediaState = { ...this.initialStateValue };
  addUpdateMediaState = { ...this.initialStateValue };
  addUpdateFolderState = { ...this.initialStateValue };
  copyFolderTenantState = { ...this.initialStateValue };
  deleteMediaFolderState = { ...this.initialStateValue };
  getStyleByCategoryIdState = { ...this.initialStateValue };

  /**
   * This is the constructor part of the store where we assign the variables & methods as Observables, Actions & Computed
   */
  constructor() {
    makeObservable(this, {
      allImages: observable,
      mediaList: observable,
      mediaImage: observable,
      mediaVideo: observable,
      mediaState: observable,
      inProgress: observable,
      allFolders: observable,
      mediaFolder: observable,
      allTenantImages: observable,
      mediaFolderList: observable,
      deleteMediaState: observable,
      mediaFolderState: observable,
      uploadMediaState: observable,
      getAllImagesState: observable,
      getAllFolderState: observable,
      addUpdateMediaState: observable,
      folderStyleCategory: observable,
      addUpdateFolderState: observable,
      mediaFolderListState: observable,
      copyFolderTenantState: observable,
      deleteMediaFolderState: observable,
      getStyleByCategoryIdState: observable,

      GetAllImages: action,
      GetAllFolders: action,
      GetMediaListService: action,
      GetFolderListService: action,
      AddImageMediaService: action,
      AddVideoMediaService: action,
      AddMediaFolderService: action,
      GetAllImagesByFolderId: action,
      UpdateVideoMediaService: action,
      UploadImageMediaService: action,
      UploadVideoMediaService: action,
      DeleteMediaImageService: action,
      DeleteMediaVideoService: action,
      UpdateImageMediaService: action,
      GetMediaVideoByIdService: action,
      GetMediaImageByIdService: action,
      UpdateMediaFolderService: action,
      DeleteMediaFolderService: action,
      UpdatePricingMediaService: action,
      GetFolderStyleByCategoryId: action,
      AddPricingImageMediaService: action,
      GetMediaFolderByCategoryIdService: action,
      CopyFolderFromAnotherTenantService: action,

      reset: action,
      resetCopyFolderState: action,
      resetVideoMediaDetail: action,
      resetDeleteMediaState: action,
      resetImageMediaDetail: action,
      resetUploadMediaState: action,
      resetFolderMediaDetail: action,
      resetGetAllImagesState: action,
      resetGetAllFoldersState: action,
      resetAddUpdateMediaState: action,
      resetAddUpdateFolderState: action,
      resetDeleteMediaFolderState: action,
      resetStyleByCategoryIdState: action,

      folderDetail: computed,
      allMediaList: computed,
      allFolderList: computed,
      imageMediaDetail: computed,
      videoMediaDetail: computed,
      folderStyleDetail: computed,
      allAvailableImages: computed,
      allAvailableFolders: computed,
      allAvailableFoldersRouteGuidance: computed,

      allStatusImageFolders: computed,
      allPriceImagesFolders: computed,
      allCarSlotImageFolders: computed,
      pricingImageMediaDetail: computed,
      allAvailableTenantImages: computed,
    });
  }

  /**
   * This function is used to get Media List [Image & Video List Array] with pagination by calling API.
   * @param pageNumber : Page Number
   * @param pageSize : Page Size
   * @returns
   */
  GetMediaListService = (
    currentPage: number,
    pagerSize: number,
    tenantId: number,
    folderId: number
  ) => {
    this.inProgress = true;
    const url =
      URLConstants.GetMediaList +
      "?PageNo=" +
      currentPage +
      "&PageSize=" +
      pagerSize +
      "&tenantId=" +
      tenantId +
      "&folderId=" +
      folderId;
    return baseService
      .getRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<IMediaListVM>>) => {
        if (response.data.Error) {
          this.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else {
          this.mediaList = response.data.Data;
        }
      })
      .catch((err: string) => {
        this.error = err;
      })
      .finally(
        action(() => {
          this.inProgress = false;
        })
      );
  };

  /**
   * This function is used to get Folder List with pagination by calling API.
   * @param pageNumber : Page Number
   * @param pageSize : Page Size
   * @returns
   */
  GetFolderListService = (
    currentPage: number,
    pagerSize: number,
    tenantId: number
  ) => {
    this.mediaFolderListState.inProgress = true;
    const url =
      URLConstants.GetMediaFolderList +
      "?PageNo=" +
      currentPage +
      "&PageSize=" +
      pagerSize +
      "&tenantId=" +
      tenantId;
    return baseService
      .getRequest(url)
      .then(
        (response: IApiResponse<IApiSuccessResponse<IMediaFolderListVM>>) => {
          if (response.data.Error) {
            this.mediaFolderListState.error = response.data.Message;
            toast.error(formatMessage(response.data.Message));
          } else {
            this.mediaFolderList = response.data.Data;
          }
        }
      )
      .catch((err: string) => {
        this.mediaFolderListState.error = err;
      })
      .finally(
        action(() => {
          this.mediaFolderListState.inProgress = false;
        })
      );
  };

  GetAllFolders = (tenantId: number) => {
    this.getAllFolderState.inProgress = true;
    const url = URLConstants.GetAllMediaFolders + "?tenantId=" + tenantId;
    return baseService
      .getRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
        if (response.data.Error) {
          this.getAllFolderState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else {
          this.allFolders = response.data.Data;
          this.getAllFolderState.success = true;
        }
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.getAllFolderState.inProgress = false;
        })
      );
  };

  GetAllImagesByFolderId = (folderId: number, tenantId: number) => {
    this.getAllImagesState.inProgress = true;
    const url =
      URLConstants.GetAllImagesFolderId +
      "?folderId=" +
      folderId +
      "&tenantId=" +
      tenantId;
    return baseService
      .getRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
        if (response.data.Error) {
          this.getAllImagesState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else {
          this.allImages = response.data.Data;
          this.getAllImagesState.success = true;
        }
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.getAllImagesState.inProgress = false;
        })
      );
  };

  GetAllImages = (tenantId: number) => {
    this.getAllImagesState.inProgress = true;
    const url = URLConstants.GetAllImages + "?tenantId=" + tenantId;
    return baseService
      .getRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
        if (response.data.Error) {
          this.getAllImagesState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else {
          this.allTenantImages = response.data.Data;
          this.getAllImagesState.success = true;
        }
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.getAllImagesState.inProgress = false;
        })
      );
  };

  GetFolderStyleByCategoryId = (categoryId: number) => {
    this.getStyleByCategoryIdState.inProgress = true;
    const url = URLConstants.GetFolderStyleById + "?categorytype=" + categoryId;
    return baseService
      .getRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
        if (response.data.Error) {
          this.getStyleByCategoryIdState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else {
          this.folderStyleCategory = response.data.Data;
          this.getStyleByCategoryIdState.success = true;
        }
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.getStyleByCategoryIdState.inProgress = false;
        })
      );
  };

  get folderStyleDetail() {
    if (this.folderStyleCategory) {
      return {
        Id: this.folderStyleCategory.Id,
        CategoryType: this.folderStyleCategory.CategoryType,
        MediaTopMargin: this.folderStyleCategory.MediaTopMargin,
        MediaLeftMargin: this.folderStyleCategory.MediaLeftMargin,
      };
    }
    return GetFolderStyleByCategoryidInitialState;
  }

  /**
   * This function is used to Add New Image Media by calling an API & sending the required Image details.
   * @param image : Image Details
   * @returns
   */
  AddImageMediaService = (data: any) => {
    this.addUpdateMediaState.inProgress = true;
    let url = URLConstants.AddImage;
    const formData = new FormData();
    formData.append("TenantId", data.TenantId);
    formData.append("FolderId", data.FolderId);
    formData.append("MediaType", data.MediaType);
    formData.append("ImageName", data.ImageName);
    formData.append("FolderCategoryType", data.FolderCategoryType);
    formData.append("PricingValue", data.PricingValue);
    formData.append("IsGenerateHTML", data.IsGenerateHTML);
    formData.append("File", data.File);
    formData.append("Base64", data.Base64);
    formData.append("Comment", data.Comment);
    formData.append("Height", data.Height);
    formData.append("Width", data.Width);
    return baseService
      .postMultipart(url, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.addUpdateMediaState.error = response.data.Message;
        } else this.addUpdateMediaState.success = true;
      })
      .catch((err: string) => {
        this.addUpdateMediaState.error = err;
      })
      .finally(
        action(() => {
          this.addUpdateMediaState.inProgress = false;
        })
      );
  };

  AddPricingImageMediaService = (data: any) => {
    this.addUpdateMediaState.inProgress = true;
    let url = URLConstants.AddPricingImage;
    const formData = new FormData();
    formData.append("TenantId", data.TenantId);
    formData.append("FolderId", data.FolderId);
    // formData.append("PriceImagesDetails", data.PriceImagesDetails);
    data.PriceImagesDetails.forEach((imageDetail: any, index: any) => {
      formData.append(
        `PriceImagesDetails[${index}].ImageName`,
        imageDetail.ImageName
      );
      formData.append(
        `PriceImagesDetails[${index}].PricingValue`,
        imageDetail.PricingValue.toString()
      );
      formData.append(
        `PriceImagesDetails[${index}].IsGenerateHtml`,
        imageDetail.IsGenerateHtml.toString()
      );

      // Ensure the file is appended correctly
      if (imageDetail.File) {
        formData.append(
          `PriceImagesDetails[${index}].File`,
          imageDetail.File,
          imageDetail.File.name
        );
      }
    });
    return baseService
      .postMultipart(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.addUpdateMediaState.error = response.data.Message;
        } else this.addUpdateMediaState.success = true;
      })
      .catch((err: string) => {
        this.addUpdateMediaState.error = err;
      })
      .finally(
        action(() => {
          this.addUpdateMediaState.inProgress = false;
        })
      );
  };

  /**
   * This function is used to Add New Video Media by calling an API & sending the required Video details.
   * @param video : Video Details
   * @returns
   */
  AddVideoMediaService = (data: any) => {
    this.addUpdateMediaState.inProgress = true;
    let url = URLConstants.AddVideo;
    this.addUpdateMediaState.inProgress = true;
    const formData = new FormData();
    formData.append("TenantId", data.TenantId);
    formData.append("FolderId", data.FolderId);
    formData.append("MediaType", data.MediaType);
    formData.append("VideoName", data.VideoName);
    formData.append("IsGenerateHtml", data.IsGenerateHTML);
    formData.append("Comment", data.Comment);
    formData.append("File", data.File);
    formData.append("Height", data.Height);
    formData.append("Width", data.Width);
    return baseService
      .postMultipart(url, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.addUpdateMediaState.error = response.data.Message;
        } else this.addUpdateMediaState.success = true;
      })
      .catch((err: string) => {
        this.addUpdateMediaState.error = err;
      })
      .finally(
        action(() => {
          this.addUpdateMediaState.inProgress = false;
        })
      );
  };

  /**
   * This function is used to Add New Media Folder by calling an API & sending the required Folder details.
   * @param folder : Folder Details
   * @returns
   */
  AddMediaFolderService = (data: any) => {
    this.addUpdateFolderState.inProgress = true;
    let url = URLConstants.AddMediaFolder;
    return baseService
      .postRequest(url, data)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.addUpdateFolderState.error = response.data.Message;
        } else this.addUpdateFolderState.success = true;
      })
      .catch((err: string) => {
        this.addUpdateFolderState.error = err;
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.addUpdateFolderState.inProgress = false;
        })
      );
  };

  UpdateImageMediaService = (id: number, data: any) => {
    this.addUpdateMediaState.inProgress = true;
    let url = URLConstants.UpdateImage;
    return baseService
      .putRequest(url, data)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.addUpdateMediaState.error = response.data.Message;
        } else this.addUpdateMediaState.success = true;
      })
      .catch((err: string) => {
        this.addUpdateMediaState.error = err;
      })
      .finally(
        action(() => {
          this.addUpdateMediaState.inProgress = false;
        })
      );
  };

  UploadImageMediaService = (data: any) => {
    this.uploadMediaState.inProgress = true;
    let url = URLConstants.UploadImage;
    const formData = new FormData();
    formData.append("TenantId", data.TenantId);
    formData.append("Id", data.Id);
    formData.append("SourcePath", data.SourcePath);
    formData.append("File", data.File);
    formData.append("Base64", data.Base64);
    formData.append("Height", data.Height);
    formData.append("Width", data.Width);
    return baseService
      .postMultipart(url, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.uploadMediaState.error = response.data.Message;
        } else this.uploadMediaState.success = true;
      })
      .catch((err: string) => {
        this.uploadMediaState.error = err;
      })
      .finally(
        action(() => {
          this.uploadMediaState.inProgress = false;
        })
      );
  };

  UpdatePricingMediaService = (data: any) => {
    this.addUpdateMediaState.inProgress = true;
    let url = URLConstants.UpdatePricingImage;
    const formData = new FormData();
    formData.append("Id", data.Id);
    formData.append("TenantId", data.TenantId);
    formData.append("FolderId", data.FolderId);
    formData.append("ImageName", data.ImageName);
    formData.append("PricingValue", data.PricingValue);
    formData.append("File", data.File);
    formData.append("IsGenerateHtml", data.IsGenerateHtml);
    return baseService
      .postMultipart(url, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.addUpdateMediaState.error = response.data.Message;
        } else this.addUpdateMediaState.success = true;
      })
      .catch((err: string) => {
        this.addUpdateMediaState.error = err;
      })
      .finally(
        action(() => {
          this.addUpdateMediaState.inProgress = false;
        })
      );
  };

  UploadVideoMediaService = (data: any) => {
    this.uploadMediaState.inProgress = true;
    let url = URLConstants.UploadVideo;
    const formData = new FormData();
    formData.append("TenantId", data.TenantId);
    formData.append("Id", data.Id);
    formData.append("SourcePath", data.SourcePath);
    formData.append("File", data.File);
    formData.append("Height", data.Height);
    formData.append("Width", data.Width);
    return baseService
      .postMultipart(url, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.uploadMediaState.error = response.data.Message;
        } else this.uploadMediaState.success = true;
      })
      .catch((err: string) => {
        this.uploadMediaState.error = err;
      })
      .finally(
        action(() => {
          this.uploadMediaState.inProgress = false;
        })
      );
  };

  UpdateVideoMediaService = (data: any) => {
    this.addUpdateMediaState.inProgress = true;
    let url = URLConstants.UpdateVideo;
    return baseService
      .putRequest(url, data)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.addUpdateMediaState.error = response.data.Message;
        } else this.addUpdateMediaState.success = true;
      })
      .catch((err: string) => {
        this.addUpdateMediaState.error = err;
      })
      .finally(
        action(() => {
          this.addUpdateMediaState.inProgress = false;
        })
      );
  };

  UpdateMediaFolderService = (data: any) => {
    this.addUpdateFolderState.inProgress = true;
    let url = URLConstants.UpdateMediaFolder;
    return baseService
      .putRequest(url, data)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.addUpdateFolderState.error = response.data.Message;
        } else this.addUpdateFolderState.success = true;
      })
      .catch((err: string) => {
        this.addUpdateFolderState.error = err;
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.addUpdateFolderState.inProgress = false;
        })
      );
  };

  DeleteMediaFolderService = (id: number, tenantId: number) => {
    this.deleteMediaFolderState.inProgress = true;
    let url =
      URLConstants.DeleteMediaFolder +
      "?folder_id=" +
      id +
      "&tenantId=" +
      tenantId;
    return baseService
      .deleteRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.deleteMediaFolderState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else this.deleteMediaFolderState.success = true;
      })
      .catch((err: string) => {
        this.deleteMediaFolderState.error = err;
      })
      .finally(
        action(() => {
          this.deleteMediaFolderState.inProgress = false;
        })
      );
  };

  DeleteMediaImageService = (id: number, tenantId: number) => {
    this.deleteMediaState.inProgress = true;
    let url = URLConstants.DeleteImage + "?id=" + id + "&tenantId=" + tenantId;
    return baseService
      .deleteRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.deleteMediaState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else this.deleteMediaState.success = true;
      })
      .catch((err: string) => {
        this.deleteMediaState.error = err;
      })
      .finally(
        action(() => {
          this.deleteMediaState.inProgress = false;
        })
      );
  };

  DeleteMediaVideoService = (id: number, tenantId: number) => {
    this.deleteMediaState.inProgress = true;
    let url = URLConstants.DeleteVideo + "?id=" + id + "&tenantId=" + tenantId;
    return baseService
      .deleteRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.deleteMediaState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else this.deleteMediaState.success = true;
      })
      .catch((err: string) => {
        this.deleteMediaState.error = err;
      })
      .finally(
        action(() => {
          this.deleteMediaState.inProgress = false;
        })
      );
  };

  /**
   * This function is used to Get Media Details by Id byb calling API
   */

  GetMediaImageByIdService = (id: number, tenantId: number) => {
    this.mediaState.inProgress = true;
    let url = URLConstants.GetImageById + "?id=" + id + "&tenantId=" + tenantId;
    return baseService
      .getRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<IMediaImageVM>>) => {
        if (response.data.Error) {
          this.mediaState.error = response.data.Message;
        } else {
          let data = response.data.Data;
          this.mediaImage = data;
        }
      })
      .catch((err: string) => {
        this.mediaState.error = err;
      })
      .finally(
        action(() => {
          this.mediaState.inProgress = false;
        })
      );
  };

  GetMediaVideoByIdService = (id: number, tenantId: number) => {
    this.mediaState.inProgress = true;
    let url = URLConstants.GetVideoById + "?id=" + id + "&tenantId=" + tenantId;
    return baseService
      .getRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<IMediaVideoVM>>) => {
        if (response.data.Error) {
          this.mediaState.error = response.data.Message;
        } else {
          let data = response.data.Data;
          this.mediaVideo = data;
        }
      })
      .catch((err: string) => {
        this.mediaState.error = err;
      })
      .finally(
        action(() => {
          this.mediaState.inProgress = false;
        })
      );
  };

  GetMediaFolderByIdService = (id: number, tenantId: number) => {
    this.mediaFolderState.inProgress = true;
    let url =
      URLConstants.GetMediaFolderById +
      "?folderid=" +
      id +
      "&tenantId=" +
      tenantId;
    return baseService
      .getRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<IMediaVideoVM>>) => {
        if (response.data.Error) {
          this.mediaFolderState.error = response.data.Message;
        } else {
          let data = response.data.Data;
          this.mediaFolder = data;
        }
      })
      .catch((err: string) => {
        this.mediaFolderState.error = err;
      })
      .finally(
        action(() => {
          this.mediaFolderState.inProgress = false;
        })
      );
  };

  GetMediaFolderByCategoryIdService = (id: number, tenantId: number) => {
    this.mediaFolderState.inProgress = true;
    let url =
      URLConstants.GetFolderByCategoryId +
      "?foldercategorytype=" +
      id +
      "&tenantId=" +
      tenantId;
    return baseService
      .getRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
        if (response.data.Error) {
          this.mediaFolderState.error = response.data.Message;
        } else {
          let data = response.data.Data;
          this.allFolders = data;
        }
      })
      .catch((err: string) => {
        this.mediaFolderState.error = err;
      })
      .finally(
        action(() => {
          this.mediaFolderState.inProgress = false;
        })
      );
  };

  CopyFolderFromAnotherTenantService = (obj: any) => {
    this.copyFolderTenantState.inProgress = true;
    let url = URLConstants.CopyFolder;
    return baseService
      .postRequest(url, obj)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.copyFolderTenantState.error = response.data.Message;
        } else this.copyFolderTenantState.success = true;
      })
      .catch((err: string) => {
        this.copyFolderTenantState.error = err;
      })
      .finally(
        action(() => {
          this.copyFolderTenantState.inProgress = false;
        })
      );
  };

  /**
   * This function is used to map all available folder List for Add Media Folder Component.
   * @returns All Available Folders
   */
  get allAvailableFolders(): IFolderOption[] {
    const folderOptions: IFolderOption[] = [
      {
        id: -1,
        value: "please_select",
        disabled: true,
      },
    ];
    if (this.allFolders && this.allFolders?.length > 0)
      this.allFolders.map((folder) => {
        folderOptions.push({
          id: folder.FolderId,
          value:
            folder.FolderName +
            " (" +
            formatMessage(folderCategoryTypeEnum[folder.FolderCategoryType]) +
            ")",
          disabled: false,
          category: folderCategoryTypeEnum[folder.FolderCategoryType],
        });
      });
    return folderOptions;
  }

  /**
   * This function is used to map all available folder List for Add Media Folder Component.
   * @returns All Available Folders
   */
  get allAvailableFoldersRouteGuidance(): IFolderOption[] {
    const folderOptions: IFolderOption[] = [
      {
        id: -1,
        value: "please_select",
        disabled: true,
      },
    ];
    if (this.allFolders && this.allFolders?.length > 0)
      this.allFolders.map((folder) => {
        folderOptions.push({
          id: folder.FolderId,
          value: folder.FolderName,
          disabled: false,
          category: folderCategoryTypeEnum[folder.FolderCategoryType],
        });
      });
    return folderOptions;
  }

  /**
   * This function is used to map available folder List for Add Media Folder Component.
   * @returns All Status Images Folder
   */
  get allStatusImageFolders(): IFolderOption[] {
    const folderOptions: IFolderOption[] = [
      {
        id: -1,
        value: "please_select",
        disabled: true,
      },
    ];
    if (this.allFolders && this.allFolders?.length > 0)
      this.allFolders.map((folder) => {
        if (folder.FolderCategoryType == folderCategoryTypeEnum.StatusImages) {
          folderOptions.push({
            id: folder.FolderId,
            value: folder.FolderName,
            disabled: false,
            category: folderCategoryTypeEnum[folder.FolderCategoryType],
          });
        }
      });
    return folderOptions;
  }

  /**
   * This function is used to map available folder List for Add Media Folder Component.
   * @returns All Price Image Folders
   */
  get allPriceImagesFolders(): IFolderOption[] {
    const folderOptions: IFolderOption[] = [
      {
        id: -1,
        value: "please_select",
        disabled: true,
      },
    ];
    if (this.allFolders && this.allFolders?.length > 0)
      this.allFolders.map((folder) => {
        if (folder.FolderCategoryType == folderCategoryTypeEnum.PriceImages) {
          folderOptions.push({
            id: folder.FolderId,
            value: folder.FolderName,
            disabled: false,
            category: folderCategoryTypeEnum[folder.FolderCategoryType],
          });
        }
      });
    return folderOptions;
  }

  /**
   * This function is used to map available folder List for Add Media Folder Component.
   * @returns All Car Slot Images folders
   */
  get allCarSlotImageFolders(): IFolderOption[] {
    const folderOptions: IFolderOption[] = [
      {
        id: -1,
        value: "please_select",
        disabled: true,
      },
    ];
    if (this.allFolders && this.allFolders?.length > 0)
      this.allFolders.map((folder) => {
        if (folder.FolderCategoryType == folderCategoryTypeEnum.CarSlotImages) {
          folderOptions.push({
            id: folder.FolderId,
            value: folder.FolderName,
            disabled: false,
            category: folderCategoryTypeEnum[folder.FolderCategoryType],
          });
        }
      });
    return folderOptions;
  }

  /**
   * This function is used to map available folder List for Add Media Folder Component.
   * @returns Initial Group Details
   */
  get allAvailableImages(): IImageOption[] {
    const imageOptions: IImageOption[] = [
      {
        id: -1,
        value: "please_select",
        disabled: true,
        base64: "",
      },
    ];
    if (this.allImages && this.allImages?.length > 0)
      this.allImages.map((image) => {
        imageOptions.push({
          id: image.Id,
          value: image.ImageName,
          disabled: false,
          base64: image.Base64,
          folder: image.Folder,
        });
      });
    return imageOptions;
  }

  get allAvailableTenantImages(): IImageOption[] {
    const imageOptions: IImageOption[] = [
      {
        id: -1,
        value: "please_select",
        disabled: true,
        base64: "",
      },
    ];
    if (this.allTenantImages && this.allTenantImages?.length > 0)
      this.allTenantImages.map((image) => {
        imageOptions.push({
          id: image.Id,
          value: image.ImageName,
          disabled: false,
          base64: image.Base64,
          folder: image.Folder,
        });
      });
    return imageOptions;
  }

  /**
   * This function is used to map Image Detail of a Particular ID suitable for Grid Component.
   * @returns Initial Image Media Details
   */
  get imageMediaDetail() {
    if (this.mediaImage) {
      return {
        Id: this.mediaImage.Id,
        ImageName: this.mediaImage.ImageName,
        FolderId: this.mediaImage.FolderId,
        AccessURL: this.mediaImage.AccessURL,
        SourcePath: this.mediaImage.SourcePath,
        IsGenerateHTML: this.mediaImage.IsGenerateHTML,
        PricingValue: this.mediaImage.PricingValue,
        Comment: this.mediaImage.Comment,
        Base64: this.mediaImage.Base64,
        Height: this.mediaImage.Height,
        Width: this.mediaImage.Width,
        File: "",
      };
    }
    return AddEditImageMediaState;
  }

  /**
   * This function is used to map Image Detail of a Particular ID suitable for Grid Component.
   * @returns Initial Image Media Details
   */
  get pricingImageMediaDetail() {
    if (this.mediaImage) {
      return {
        Id: this.mediaImage.Id,

        PricingValue: this.mediaImage.PricingValue,
      };
    }
    return AddEditPricingImageMediaState;
  }

  /**
   * This function is used to map imageMediaList to allImageMediaList suitable for Grid Component.
   * @returns Initial Image Media Details
   */
  get allMediaList(): IMediaList[] {
    if (this.mediaList && this.mediaList.media?.length > 0)
      return this.mediaList.media.map((media: any, index) => {
        let isEdit = true;
        let isDelete = true;
        let isView = true;
        return {
          Id: index,
          MediaId: media.MediaId,
          MediaName: media.MediaName,
          MediaType: media.MediaType,
          PricingValue: media.PricingValue,
          Comment: media.Comment,
          isEdit: isEdit,
          isDelete: isDelete,
          isView: isView,
        };
      });
    return [];
  }

  /**
   * This function is used to map Video Detail of a Particular ID suitable for Grid Component.
   * @returns Initial VIdeo Media Details
   */
  get videoMediaDetail() {
    if (this.mediaVideo) {
      return {
        VideoId: this.mediaVideo.VideoId,
        VideoName: this.mediaVideo.VideoName,
        FolderId: this.mediaVideo.FolderId,
        AccessURL: this.mediaVideo.AccessURL,
        SourcePath: this.mediaVideo.SourcePath,
        IsGenerateHTML: this.mediaVideo.IsGenerateHTML,
        Height: this.mediaVideo.Height,
        Width: this.mediaVideo.Width,
        Comment: this.mediaVideo.Comment,
      };
    }
    return AddEditVideoMediaState;
  }

  /**
   * This function is used to map Folder Detail of a Particular ID suitable for Grid Component.
   * @returns Initial Folder Details [Computed Data]
   */
  get folderDetail() {
    if (this.mediaFolder) {
      return {
        FolderId: this.mediaFolder.FolderId,
        FolderName: this.mediaFolder.FolderName,
        ParentFolderId: this.mediaFolder.ParentFolderId,
        MediaTopMargin: this.mediaFolder.MediaTopMargin,
        MediaLeftMargin: this.mediaFolder.MediaLeftMargin,
        FolderCategoryType: this.mediaFolder.FolderCategoryType,
        IsDefaultSettings: this.mediaFolder.IsDefaultSettings,
        LeftMargin: this.mediaFolder.IsDefaultSettings
          ? 0
          : this.mediaFolder.MediaLeftMargin,
        TopMargin: this.mediaFolder.IsDefaultSettings
          ? 0
          : this.mediaFolder.MediaTopMargin,
        Comment: this.mediaFolder.Comment,
      };
    }
    return AddEditFolderState;
  }

  /**
   * This will return all the folders list from the AWS Amazon S3 Bucket & DB
   */
  get allFolderList(): any[] {
    if (this.mediaFolderList && this.mediaFolderList.MediaFolder?.length > 0)
      return this.mediaFolderList.MediaFolder.map((folder: any) => {
        let isAccessFolder = true;
        let isEdit = true;
        let isDelete = true;
        return {
          FolderId: folder.FolderId,
          FolderName: folder.FolderName,
          ParentFolderId: folder.ParentFolderId,
          MediaTopMargin: folder.MediaTopMargin,
          MediaLeftMargin: folder.MediaLeftMargin,
          FolderCategoryType: folder.FolderCategoryType,
          Comment: folder.Comment,
          isAccessFolder: isAccessFolder,
          isEdit: isEdit,
          isDelete: isDelete,
        };
      });
    return [];
  }

  /**
   * This function is used to reset getImageMediaDetail observables to their initial values.
   * @returns
   */

  resetImageMediaDetail = () => {
    this.mediaImage = undefined;
    this.mediaState = { ...this.initialStateValue };
  };

  /**
   * This function is used to reset getVideoMediaDetail observables to their initial values.
   * @returns
   */
  resetVideoMediaDetail = () => {
    this.mediaVideo = undefined;
    this.mediaState = { ...this.initialStateValue };
  };

  /**
   * This function is used to reset getFolderMediaDetails observables to their initial values.
   * @returns
   */

  resetFolderMediaDetail = () => {
    this.mediaFolder = undefined;
    this.mediaFolderState = { ...this.initialStateValue };
  };

  /**
   * This function is used to reset addUpdateMedia observables to their initial values.
   * @returns
   */
  resetAddUpdateMediaState = () => {
    this.addUpdateMediaState = { ...this.initialStateValue };
  };

  /**
   * This function is used to reset Delete Media Folder observables to their initial values.
   * @returns
   */
  resetDeleteMediaFolderState = () => {
    this.deleteMediaFolderState = { ...this.initialStateValue };
  };

  /**
   * This function is used to reset Delete Media observables to their initial values.
   * @returns
   */
  resetDeleteMediaState = () => {
    this.deleteMediaState = { ...this.initialStateValue };
  };

  /**
   * This function is used to reset addUpdateMediaFolderState observables to their initial values.
   * @returns
   */
  resetAddUpdateFolderState = () => {
    this.addUpdateFolderState = { ...this.initialStateValue };
  };

  /**
   * This function is used to reset addUpdateMediaFolderState observables to their initial values.
   * @returns
   */
  resetGetAllFoldersState = () => {
    this.allFolders = [];
    this.getAllFolderState = { ...this.initialStateValue };
  };

  /**
   * This function is used to reset addUpdateMediaFolderState observables to their initial values.
   * @returns
   */
  resetGetAllImagesState = () => {
    this.allImages = [];
    this.getAllImagesState = { ...this.initialStateValue };
  };

  resetUploadMediaState = () => {
    this.uploadMediaState = { ...this.initialStateValue };
  };

  resetStyleByCategoryIdState = () => {
    this.folderStyleCategory = undefined;
    this.getStyleByCategoryIdState = { ...this.initialStateValue };
  };

  resetCopyFolderState = () => {
    this.copyFolderTenantState = { ...this.initialStateValue };
  };
  /**
   * This method resets the whole store, including the data variable states, observables & the computed value.
   */
  reset = () => {
    this.error = "";
    this.inProgress = false;
    this.mediaImage = undefined;
    this.mediaVideo = undefined;
    this.mediaFolder = undefined;

    this.addUpdateMediaState = { ...this.initialStateValue };
    this.addUpdateFolderState = { ...this.initialStateValue };

    this.getAllFolderState = { ...this.initialStateValue };
    this.mediaFolderListState = { ...this.initialStateValue };

    this.deleteMediaState = { ...this.initialStateValue };
    this.deleteMediaFolderState = { ...this.initialStateValue };

    this.mediaState = { ...this.initialStateValue };
    this.mediaFolderState = { ...this.initialStateValue };
  };
}

export default new MediaStore();
